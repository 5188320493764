import React, { FC } from "react";
import ptBR from "~/language/pt-br.json";
import enUS from "~/language/en-us.json";
import esES from "~/language/es-es.json";
import frFR from "~/language/fr-fr.json";
import LanguageProvider from "~/shared/components/language-provider";
import * as GridItems from "../../../shared-modules/dashboard-graphs/components/grid-item/items";
import { DashboardMenuItemCategory } from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/menu-items";
import { useLanguageContext } from "~/context/LanguageContext";

const TranslatedLabel = ({ id }: { id: string }) => {
  const { currentLanguage } = useLanguageContext();

  const getTranslation = (id: string) => {
    switch (currentLanguage) {
      case "pt-br":
        return ptBR[id] || id;
      case "es-es":
        return esES[id] || id;
      case "en-us":
        return enUS[id] || id;
      case "fr-fr":
        return frFR[id] || id;
      default:
        return enUS[id] || id;
    }
  };

  return <>{getTranslation(id)}</>;
};

export const AndonComponentMenuItem = () => {
  return [
    {
      category: "layout",
      productCode: "platform",
      icon: "layout-grid-01",
      label: <TranslatedLabel id="dashboard.category.layout" />,
      items: [
        {
          type: "andon-text",
          label: <TranslatedLabel id="dashboard.item.type.text" />,
          icon: "align-left",
          component: GridItems.AndonText,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "andon",
      productCode: "sequence",
      icon: "layout-grid-01",
      label: <TranslatedLabel id="dashboard.category.andon" />,
      items: [
        {
          type: "position",
          label: <TranslatedLabel id="dashboard.item.type.andon.position" />,
          icon: "align-left",
          component: GridItems.Position,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "divider",
          label: <TranslatedLabel id="dashboard.item.type.andon.divider" />,
          icon: "align-left",
          component: GridItems.Divider,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
  ];
};
