import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../grid-item-helpers";
import fakeData from "../../fake-data/drone-inventory/counts-by-day.json";
import { ThemeContext } from "~/app";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";

export default function InventoryCountsByDay(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const dcpAxiosService = useDcpAxiosService();
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const lastDays = configs.find(
          (config) => config.defaultConfig.name === "last-days"
        );

        console.log(lastDays);

        const { data, status } = await dcpAxiosService.get(
          settings.Urls.Rest.Dashboard + "/counts_by_day_chart",
          "Inventory",
          {
            params: {
              lastDays: lastDays.value,
            },
          }
        );

        if (status !== 200 || !data.data) return;
        setChartData(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (useMockData) {
      setChartData(fakeData);
    } else {
      // load from api
      loadData();
    }
  }, [useMockData, configs]);

  const options = {
    ...defaultChartOptions,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
        stacked: true,
      },
    },
  };

  return (
    <Chart
      type="line"
      data={chartData}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
