import { Route, Routes } from "react-router-dom";

import Login from "./pages/admin/authentication/login";
import LoginConfirmation from "./pages/admin/authentication/login-confirmation";
import Logout from "./pages/admin/authentication/logout";
import Dashboard from "./pages/admin/dashboards/dashboard";
import AuthSettingsGrafana from "./pages/admin/settings/settings-grafana.jsx";
import AuthSettingsIntegrations from "./pages/admin/settings/settings-integration.jsx";
import Users from "./pages/admin/users/users";
import HomeAreaMovementControl from "./pages/area-movement-control/home/home";
import { OccupationMap } from "./pages/area-movement-control/occupation-map/occupation-map";
import Requests from "./pages/area-movement-control/requests/requests";
import RequestsDetails from "./pages/area-movement-control/requests/requests-details";
import { SettingsAreaControlMovement } from "./pages/area-movement-control/settings/settings-area-control-movement.jsx";
import SkuAreaMovementControl from "./pages/area-movement-control/skus/skus";
import SkuDetails from "./pages/area-movement-control/skus/skus-details";
import { AreaEdit } from "./pages/inventory/area-map/area-edit";
import Home from "./pages/inventory/home/home";
import { Inventory } from "./pages/inventory/inventory/Inventory";
import { InventoryDetails } from "./pages/inventory/inventory/inventory-details/inventory-details";
import Layout from "./pages/inventory/layout/layout";
import BarcodeRead from "./pages/inventory/location/barcode-read";
import { SettingsInventory } from "./pages/inventory/settings/settings.jsx";
import Examples from "./pages/platform/examples";
import NotFound from "./pages/platform/not-found";
import AndonComponent from "./pages/sequence/andon/component.jsx";
import CurrentTrip from "./pages/sequence/andon/current-trip";
import AndonTrip from "./pages/sequence/andon/trip.jsx";
import HomeSequence from "./pages/sequence/home/home";
import PartNumber from "./pages/sequence/registrations/part-number/part-number";
import PartNumberDetails from "./pages/sequence/registrations/part-number/part-number-details";
import PrintersFromPrinter from "./pages/print/printer/printers.tsx";
import PrintersDetailsFromPrinter from "./pages/print/printer/printers-details.tsx";
import Printers from "./pages/sequence/registrations/printer/printers";
import PrintersDetails from "./pages/sequence/registrations/printer/printers-details";
import SerialBlock from "./pages/sequence/registrations/serial-block/serial-block";
import SerialBlockDetails from "./pages/sequence/registrations/serial-block/serial-block-details";
import { SettingsSequence } from "./pages/sequence/settings/settings-sequence.jsx";
import TripDetails from "./pages/sequence/trips/trips-details";
import Trips from "./pages/sequence/trips/trips-list";
import DashboardGraphs from "./pages/shared-modules/dashboard-graphs/dashboard-graphs";
import DatabaseList from "./pages/shared-modules/databases/list/database-list";
import DatabaseNew from "./pages/shared-modules/databases/new/database-new";
import ReportEdit from "./pages/shared-modules/reports/report-edit";
import { ReportView } from "./pages/shared-modules/reports/report-view";
import DynamicReports from "./pages/shared-modules/reports/reports";
import Entry from "./pages/warehouse/actions/entry";
import Loan from "./pages/warehouse/actions/loan";
import { Movement } from "./pages/warehouse/actions/movement";
import Output from "./pages/warehouse/actions/output";
import { Return } from "./pages/warehouse/actions/return";
import HomeWarehouse from "./pages/warehouse/home/home";
import PurchaseRequestsDetails from "./pages/warehouse/purchase-requests/purchase-requests-details";
import PurchaseRequests from "./pages/warehouse/purchase-requests/purchase-requests-list";
import AddressesDetails from "./pages/warehouse/registrations/adresses/addresses-details";
import Addresses from "./pages/warehouse/registrations/adresses/addresses-list";
import ApplicantsDetails from "./pages/warehouse/registrations/applicants/applicants-details";
import Applicants from "./pages/warehouse/registrations/applicants/applicants-list";
import FamilyDetails from "./pages/warehouse/registrations/family/family-details";
import Family from "./pages/warehouse/registrations/family/family-list";
import ProviderDetails from "./pages/warehouse/registrations/providers/providers-details";
import Providers from "./pages/warehouse/registrations/providers/providers-list";
import SkusWarehouse from "./pages/warehouse/registrations/skus/sku-list";
import SkuWarehouseDetails from "./pages/warehouse/registrations/skus/skus-details";
import { SettingsWarehouse } from "./pages/warehouse/settings/settings-warehouse.jsx";
import DispatchDetails from "./pages/wms/dispatch/dispatch-details";
import Dispatch from "./pages/wms/dispatch/dispatch-list";
import HomeWms from "./pages/wms/home/home";
import WmsOccupationMap from "./pages/wms/occupation-map/occupation-map";
import ReceivingDetails from "./pages/wms/receiving/receiving-details";
import Receiving from "./pages/wms/receiving/receiving-list";
import DockDetails from "./pages/wms/registrations/docks/dock-details";
import Docks from "./pages/wms/registrations/docks/docks";
import Families from "./pages/wms/registrations/families/families";
import FamiliesDetails from "./pages/wms/registrations/families/families-details";
import Skus from "./pages/wms/registrations/skus/skus";
import SkusDetails from "./pages/wms/registrations/skus/skus-details";
import { SettingsWms } from "./pages/wms/settings/settings-wms.jsx";
import { PreLoggedInRoutes } from "./pre-logged-routes";
import ProtectedRoutes from "./protected-routes";
import AuthLayout from "./shared/auth-layout";
import DashboardLayout from "./shared/dashboard-layout";
import LoginLayout from "./shared/login-layout";
import MainLayout from "./shared/main-layout";
import ThemeLayout from "./shared/theme-layout";
import HomeProductWd from "./pages/product-wd/home/home.jsx";
import AccountingBalances from "./pages/product-wd/accounting-balances/accounting-balances.jsx";
import FixedAssets from "./pages/product-wd/fixed-assets/fixed-assets.jsx";
import OpenTitles from "./pages/product-wd/open-titles/open-titles.jsx";
import XmlFiles from "./pages/product-wd/xml-files/xml-files.jsx";
import { SettingsProductDw } from "./pages/product-wd/settings/settings-product-dw.jsx";
import Containers from "./pages/wms/registrations/containers/containers.jsx";
import ContainersDetails from "./pages/wms/registrations/containers/container-details.jsx";
import SettingsDataManagement from "./pages/admin/settings/settings-data-management.jsx";
import { FloorArea } from "./pages/inventory/floor-area/floor-area";
import { FloorAreaEdit } from "./pages/inventory/floor-area/floor-area-edit";
import { RackList } from "./pages/inventory/layout/rack-list";
import { AreaMapList } from "./pages/inventory/area-map/area-map-list";
import TransferBranches from "./pages/warehouse/actions/transfer-branches.jsx";
import { Discard } from "./pages/warehouse/actions/discard";
import { AreaMapHistory } from "./pages/inventory/area-map-history/area-map-history";
import HomePrint from "./pages/print/home/print.jsx";
import HomeStageControl from "./pages/stage-control/home/stage-control.jsx";
import PrintedData from "./pages/print/printed-data/printed-data.tsx";
import PrintAction from "./pages/print/print-action/print.tsx";
import TemplateMain from "./pages/print/template-manage/template.jsx";
import { TemplateList } from "./pages/print/template-manage/template-list.tsx";
import InventoryStock from "./pages/inventory/inventory-stock/inventory-stock.tsx";
import HomeDocumentsPrint from "./pages/documents-print/home/documents-print.jsx";
import PrintInsert from "./pages/documents-print/print-insert/printInsert.tsx";
import PrintInterface from "./pages/documents-print/print-interface/printInterface.tsx";
import Print from "./pages/documents-print/print/print.tsx";
import {
  SettingsDocumentPrint,
  SettingsPrint,
} from "./pages/documents-print/settings/settings-document-print.jsx";
import PrintDetails from "./pages/documents-print/print-insert/print-details.tsx";
import SKUSInventory from "./pages/inventory/skus/sku.tsx";
import SkusInventoryDetails from "./pages/inventory/skus/skus-details.tsx";
import PrinterInventory from "./pages/inventory/printer/printers.tsx";
import IdentificacaoImpressaoInterfaceInventory from "./pages/inventory/identification-print-interface/identification-print-interface.tsx";
import IdentificationPrint from "./pages/inventory/identification-print/identification-print.tsx";
import PickingCount from "./pages/inventory/picking-count-data/picking-count.tsx";
import { AreaMapPickingCount } from "./pages/inventory/picking-count-map/area-map-picking-count.tsx";
import StageControlOrders from "./pages/stage-control/orders/order-list.jsx";
import StageControlOrderItem from "./pages/stage-control/orders/order-details.jsx";
import { SettingsProductPrint } from "./pages/print/settings/settings-product-print.jsx";
import WMSCoreCevaPickingCartao from "./pages/wms-core-ceva/picking-cartao/picking-cartao.tsx";
import SkusWmsCoreCeva from "./pages/wms-core-ceva/skus/sku.tsx";
import SkusWmsCoreCevaDetails from "./pages/wms-core-ceva/skus/skus-details.tsx";
import EstacaooTrabalhoWmsCoreCeva from "./pages/wms-core-ceva/estacao-trabalho/estacao.tsx";
import { GptAI } from "./pages/admin/settings/settings-gpt-ai.jsx";
import { SettingsWMSCoreCeva } from "./pages/wms-core-ceva/settings/settings-wms-core-ceva.jsx";
import WMSCoreCevaPickingMulti from "./pages/wms-core-ceva/picking-multi/picking-multi.tsx";

const NavProvider = () => {
  return (
    <Routes>
      <Route element={<ThemeLayout />}>
        {/* Unprotected Routes */}
        <Route path="auth" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="login/confirmation" element={<LoginConfirmation />} />
          {/* <Route path="password-reset" element={<PasswordReset />} /> */}
          <Route path="logout" element={<Logout />} />
        </Route>

        {/* Pre Logged In Routes */}
        <Route element={<PreLoggedInRoutes />}>
          <Route path="dashboard-graphs/external">
            <Route
              path=":idDashboard"
              element={<DashboardGraphs external={true} />}
            ></Route>
          </Route>
        </Route>

        {/* Protected Routes */}
        <Route element={<ProtectedRoutes />}>
          <Route element={<DashboardLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="database">
              <Route path="" />
              <Route path="list/:idDatabase" element={<DatabaseList />} />
              <Route path="edit/:idDatabase" element={<DatabaseNew />} />
              <Route path="new" element={<DatabaseNew />} />
            </Route>
            <Route path="dashboard-graphs">
              <Route path=":idDashboard" element={<DashboardGraphs />}></Route>
            </Route>
            <Route path="reports/:idReport" element={<ReportView />} />
            <Route path="reports/edit/:idReport" element={<ReportEdit />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/users" element={<Users />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route
              path="/integrations"
              element={<AuthSettingsIntegrations />}
            />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/grafana" element={<AuthSettingsGrafana />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route
              path="/data-management"
              element={<SettingsDataManagement />}
            />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/gpt-ai" element={<GptAI />} />
          </Route>

          {/* Inventory */}
          <Route path=":projectId" element={<MainLayout />}>
            <Route path="home/drone-inventory" element={<Home />} />
            <Route path="inventory">
              <Route path="" element={<Inventory />} />
              <Route path=":idInventory" element={<InventoryDetails />} />
            </Route>
            <Route path="receiving">
              <Route path="" element={<Receiving />}></Route>
              <Route
                path="receiving-items/:itemId"
                element={<ReceivingDetails />}
              ></Route>
            </Route>
            <Route path="dispatch">
              <Route path="" element={<Dispatch />}></Route>
              <Route
                path="dispatch-items/:itemId"
                element={<DispatchDetails />}
              ></Route>
            </Route>
            <Route path="area-map">
              <Route path="" element={<AreaMapList />}></Route>
              <Route path="edit/:idAreaMap" element={<AreaEdit />}></Route>
            </Route>
            <Route path="area-map-history" element={<AreaMapHistory />}></Route>
            <Route
              path="picking-count-map"
              element={<AreaMapPickingCount />}
            ></Route>
            <Route
              path="picking-count-interface"
              element={<PickingCount />}
            ></Route>
            <Route
              path="identification-print"
              element={<IdentificationPrint />}
            ></Route>
            <Route path="floor-area">
              <Route path="" element={<FloorArea />}></Route>
              <Route
                path="edit/:floorAreaId"
                element={<FloorAreaEdit />}
              ></Route>
            </Route>
            <Route path="skuInventory">
              <Route path="" element={<SKUSInventory />}></Route>
              <Route
                path="skusinventory-items/:itemId"
                element={<SkusInventoryDetails />}
              ></Route>
            </Route>
            <Route path="printerInventory">
              <Route path="" element={<PrinterInventory />}></Route>
            </Route>

            <Route path="identification-interface">
              <Route
                path=""
                element={<IdentificacaoImpressaoInterfaceInventory />}
              ></Route>
            </Route>

            <Route path="inventoryStock">
              <Route path="" element={<InventoryStock />}></Route>
            </Route>

            <Route path="barcode-read" element={<BarcodeRead />} />
            <Route path="settings-inventory" element={<SettingsInventory />} />

            {/* WMS */}
            <Route path="home/wms" element={<HomeWms />} />
            <Route path="docks">
              <Route path="" element={<Docks />}></Route>
              <Route
                path="dock-items/:itemId"
                element={<DockDetails />}
              ></Route>
            </Route>
            <Route path="families">
              <Route path="" element={<Families />}></Route>
              <Route
                path="families-items/:itemId"
                element={<FamiliesDetails />}
              ></Route>
            </Route>
            <Route path="containers">
              <Route path="" element={<Containers />}></Route>
              <Route
                path="containers-items/:itemId"
                element={<ContainersDetails />}
              ></Route>
            </Route>
            <Route path="skus">
              <Route path="" element={<Skus />}></Route>
              <Route
                path="skus-items/:itemId"
                element={<SkusDetails />}
              ></Route>
            </Route>
            <Route path="wms-occupation-map" element={<WmsOccupationMap />} />
            <Route path="settings-wms" element={<SettingsWms />} />

            {/* Sequence */}
            <Route path="home/sequence" element={<HomeSequence />} />
            <Route path="part-number">
              <Route path="" element={<PartNumber />}></Route>
              <Route
                path="partnumber-items/:itemId"
                element={<PartNumberDetails />}
              ></Route>
            </Route>
            <Route path="serial-block">
              <Route path="" element={<SerialBlock />}></Route>
              <Route
                path="serialblock-items/:itemId"
                element={<SerialBlockDetails />}
              ></Route>
            </Route>
            <Route path="printers">
              <Route path="" element={<Printers />}></Route>
              <Route
                path="printers-items/:itemId"
                element={<PrintersDetails />}
              ></Route>
            </Route>
            <Route path="layout">
              <Route path="" element={<RackList />} />
              <Route path="edit/:rackId" element={<Layout />} />
            </Route>
            <Route path="trips">
              <Route path="" element={<Trips />}></Route>
              <Route
                path="trip-items/:itemId"
                element={<TripDetails />}
              ></Route>
            </Route>
            <Route path="andon/trip">
              <Route path="" element={<AndonTrip />}></Route>
            </Route>
            <Route path="andon/component">
              <Route path="" element={<AndonComponent />}></Route>
            </Route>
            <Route path="current-trip">
              <Route path="" element={<CurrentTrip />}></Route>
            </Route>
            <Route path="settings-sequence" element={<SettingsSequence />} />

            {/* Warehouse */}
            <Route path="home/warehouse" element={<HomeWarehouse />} />
            <Route path="sku-warehouse">
              <Route path="" element={<SkusWarehouse />}></Route>
              <Route
                path="skus-warehouse-items/:itemId"
                element={<SkuWarehouseDetails />}
              ></Route>
            </Route>
            <Route path="family">
              <Route path="" element={<Family />}></Route>
              <Route
                path="family-items/:itemId"
                element={<FamilyDetails />}
              ></Route>
            </Route>
            <Route path="applicants">
              <Route path="" element={<Applicants />}></Route>
              <Route
                path="applicants-items/:itemId"
                element={<ApplicantsDetails />}
              ></Route>
            </Route>
            <Route path="addresses">
              <Route path="" element={<Addresses />}></Route>
              <Route
                path="addresses-items/:itemId"
                element={<AddressesDetails />}
              ></Route>
            </Route>
            <Route path="providers">
              <Route path="" element={<Providers />}></Route>
              <Route
                path="providers-items/:itemId"
                element={<ProviderDetails />}
              ></Route>
            </Route>
            <Route path="purchase-requests">
              <Route path="" element={<PurchaseRequests />}></Route>
              <Route
                path="purchase-requests-items/:itemId"
                element={<PurchaseRequestsDetails />}
              ></Route>
            </Route>
            <Route path="entry" element={<Entry />} />
            <Route path="loan" element={<Loan />} />
            <Route path="movement" element={<Movement />} />
            <Route path="discard" element={<Discard />} />
            <Route path="output" element={<Output />} />
            <Route path="transfer-branches" element={<TransferBranches />} />
            <Route path="return" element={<Return />} />
            <Route path="settings-warehouse" element={<SettingsWarehouse />} />

            {/* Area control movement */}
            <Route
              path="home/area-movement-control"
              element={<HomeAreaMovementControl />}
            />
            <Route path="skus">
              <Route path="" element={<SkuAreaMovementControl />}></Route>
              <Route path="skus-items/:itemId" element={<SkuDetails />}></Route>
            </Route>
            <Route path="requests">
              <Route path="" element={<Requests />}></Route>
              <Route
                path="request-items/:itemId"
                element={<RequestsDetails />}
              ></Route>
            </Route>
            <Route path="amc-occupation-map" element={<OccupationMap />} />

            <Route path="reports">
              <Route path="" element={<DynamicReports />} />
              <Route path=":idReport" element={<ReportView />} />
              <Route path="edit/:idReport" element={<ReportEdit />} />
            </Route>
            <Route
              path="settings-area-control-movement"
              element={<SettingsAreaControlMovement />}
            />

            {/* Product WD */}
            <Route path="home/product-wd" element={<HomeProductWd />} />
            <Route
              path="accounting-balances"
              element={<AccountingBalances />}
            />
            <Route path="open-titles" element={<OpenTitles />} />
            <Route path="fixed-assets" element={<FixedAssets />} />
            <Route path="xml-files" element={<XmlFiles />} />
            <Route path="settings-product-dw" element={<SettingsProductDw />} />

            {/* Stage Control */}
            <Route path="home/stage-control" element={<HomeStageControl />} />

            <Route path="stage-orders">
              <Route path="" element={<StageControlOrders />}></Route>
              <Route
                path="stage-orders-items/:itemId"
                element={<StageControlOrderItem />}
              ></Route>
            </Route>

            {/* WMS Core CEVA */}
            <Route path="home/wms-core-ceva" element={<HomeStageControl />} />
            <Route
              path="home/wms-core-ceva-picking-cartao"
              element={<WMSCoreCevaPickingCartao />}
            />
            <Route
              path="home/wms-core-ceva-picking-multi"
              element={<WMSCoreCevaPickingMulti />}
            />

            <Route path="home/wms-core-ceva-skus">
              <Route path="" element={<SkusWmsCoreCeva />}></Route>
              <Route
                path="printers-items/:itemId"
                element={<SkusWmsCoreCevaDetails />}
              ></Route>
            </Route>

            <Route
              path="home/wms-core-ceva-estacao-trabalho"
              element={<EstacaooTrabalhoWmsCoreCeva />}
            />

            <Route
              path={"home/wms-core-ceva-settings"}
              element={<SettingsWMSCoreCeva />}
            ></Route>

            {/* Print */}
            <Route path="home/print" element={<HomePrint />} />
            <Route path="print-printers">
              <Route path="" element={<PrintersFromPrinter />}></Route>
              <Route
                path="printers-items/:itemId"
                element={<PrintersDetailsFromPrinter />}
              ></Route>
            </Route>
            <Route path="printer-data">
              <Route path="" element={<PrintedData />}></Route>
            </Route>
            <Route path="print-action">
              <Route path="" element={<PrintAction />}></Route>
            </Route>
            <Route path="template">
              <Route path="" element={<TemplateList />} />
              <Route path="edit/:templateId" element={<TemplateMain />} />
            </Route>
            <Route
              path="settings-product-print"
              element={<SettingsProductPrint />}
            />

            {/* Documents print */}
            <Route
              path="home/documents-print"
              element={<HomeDocumentsPrint />}
            />
            <Route path="document-print-printers">
              <Route path="" element={<PrintInsert />}></Route>
            </Route>
            <Route path="document-print-interface">
              <Route path="" element={<PrintInterface />}></Route>
            </Route>
            <Route path="document-print-action">
              <Route path="" element={<Print />}></Route>
            </Route>
            <Route path="settings-print" element={<SettingsDocumentPrint />} />

            <Route path="database">
              <Route path="" />
              <Route path="new" element={<DatabaseNew />} />
              <Route path="list/:idDatabase" element={<DatabaseList />}></Route>
              <Route path="edit/:idDatabase" element={<DatabaseNew />} />
            </Route>

            <Route path="dashboard-graphs">
              <Route path=":idDashboard" element={<DashboardGraphs />}></Route>
            </Route>
          </Route>
        </Route>
        <Route path="examples" element={<Examples />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavProvider;
