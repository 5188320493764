// @jsx: react

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import React, { ReactNode, createContext, useContext } from "react";
import {
  getFromStorage,
  propertyFrommStorage,
  removeStorage,
  writeStorage,
} from "../storage/storage-access";
import settings from "../settings.json";

type ValidServiceTypes =
  | "Platform"
  | "Inventory"
  | "Auth"
  | "Login"
  | "AI"
  | "WMS"
  | "MES"
  | "Sequence"
  | "Warehouse"
  | "PrintDocument"
  | "Area-Movement-Control"
  | "CustomApps";

export default class DcpAxiosService {
  private axiosInstance: AxiosInstance;
  private validServiceTypes: Record<ValidServiceTypes, boolean>;

  constructor() {
    this.axiosInstance = axios.create();
    this.validServiceTypes = {
      Platform: true,
      Inventory: true,
      Auth: true,
      Login: true,
      AI: true,
      MES: true,
      Sequence: true,
      Warehouse: true,
      WMS: true,
      "Area-Movement-Control": true,
      PrintDocument: true,
      CustomApps: true,
    };
  }

  async get(
    endpoint: string,
    serviceType: ValidServiceTypes | null = null,
    config: AxiosRequestConfig<any> | null = null
  ): Promise<any> {
    try {
      this.setHeaders(serviceType);
      const getResponse = await this.axiosInstance
        .get(this.setBasePath(endpoint, serviceType), config)
        .catch(async (error) => {
          if (error.response.status === 401) {
            const authIsRefreshed = await this.refreshAuthentication();
            if (authIsRefreshed) {
              return await this.axiosInstance.get(
                this.setBasePath(endpoint, serviceType)
              );
            }
          }
          return error;
        });
      return getResponse;
    } catch (error) {
      console.error(`GET request failed: ${error.message}`);
      return error.response;
    }
  }

  async post(
    endpoint: string,
    data: any,
    serviceType: ValidServiceTypes | null = null,
    config: AxiosRequestConfig<any> | null = null
  ): Promise<any> {
    try {
      this.setHeaders(serviceType);
      const postResponse = await this.axiosInstance
        .post(this.setBasePath(endpoint, serviceType), data, config)
        .catch(async (error) => {
          if (error.response.status === 401) {
            const authIsRefreshed = await this.refreshAuthentication();
            if (authIsRefreshed) {
              return await this.axiosInstance.post(
                this.setBasePath(endpoint, serviceType),
                data
              );
            }
          }
          return error;
        });
      return postResponse;
    } catch (error) {
      console.error(`POST request failed: ${error.message}`);
      return error.response;
    }
  }
  async delete(
    endpoint: string,
    serviceType: ValidServiceTypes | null = null,
    config: AxiosRequestConfig<any> | null = null
  ): Promise<any> {
    try {
      this.setHeaders(serviceType);
      const deleteResponse = await this.axiosInstance
        .delete(this.setBasePath(endpoint, serviceType), config)
        .catch(async (error) => {
          if (error.response.status === 401) {
            const authIsRefreshed = await this.refreshAuthentication();
            if (authIsRefreshed) {
              return await this.axiosInstance.delete(
                this.setBasePath(endpoint, serviceType)
                // Note: 'data' was not defined in the original code
              );
            }
          }
          return error;
        });
      return deleteResponse;
    } catch (error) {
      console.error(`DELETE request failed: ${error.message}`);
      return error.response;
    }
  }
  private isValidServiceType(serviceType: string): boolean {
    return this.validServiceTypes[serviceType as ValidServiceTypes] === true;
  }
  private setBasePath(
    endpoint: string,
    serviceType: ValidServiceTypes | null
  ): string {
    let path: string;
    if (this.isValidServiceType(serviceType)) {
      switch (serviceType) {
        case "Inventory":
          path = `${settings.BaseApiPath}/rest/inventory/${endpoint}`;
          break;
        case "Login":
          path = `${settings.BaseApiPath}/${endpoint}`;
          break;
        case "Auth":
          path = `${settings.BaseApiPath}/admin/${endpoint}`;
          break;
        case "AI":
          path = `${settings.BaseApiPath}/rest/ai-analytics/${endpoint}`;
          break;
        case "Warehouse":
          path = `${settings.BaseApiPath}/rest/warehouse/${endpoint}`;
          break;
        case "WMS":
          path = `${settings.BaseApiPath}/rest/wms/${endpoint}`;
          break;
        case "Platform":
          path = `${settings.BaseApiPath}/rest/platform/${endpoint}`;
          break;
        case "Area-Movement-Control":
          path = `${settings.BaseApiPath}/rest/movement-control/${endpoint}`;
          break;
        case "MES":
          path = `${settings.BaseApiPath}/rest/mes/${endpoint}`;
          break;
        case "Sequence":
          path = `${settings.BaseApiPath}/rest/sequence/${endpoint}`;
          break;
        case "PrintDocument":
          path = `${settings.BaseApiPath}/rest/print-document/${endpoint}`;
          break;
        case "CustomApps":
          path = `${settings.BaseApiPath}/rest/customapps/${endpoint}`;
          break;
        default:
          path = "";
      }
    } else {
      path = endpoint;
    }
    return path;
  }
  private setHeaders(serviceType: ValidServiceTypes): void {
    try {
      const authData = propertyFrommStorage("authentication");
      if (authData) {
        const { applicationIds = [], authToken, clientId } = authData;
        let applicationId = "";
        switch (serviceType) {
          case "Inventory":
            applicationId = applicationIds["drone-inventory"];
            break;
          case "Area-Movement-Control":
            applicationId = applicationIds["area-movement-control"];
            break;
          case "MES":
            applicationId = applicationIds.mes;
            break;
          case "Sequence":
            applicationId = applicationIds.sequence;
            break;
          case "WMS":
            applicationId = applicationIds.wms;
            break;
          case "Warehouse":
            applicationId = applicationIds.warehouse;
            break;
          case "PrintDocument":
            applicationId = applicationIds["documents-print"];
            break;
          case "Platform":
          case "Auth":
          case "AI":
          case "Login":
            applicationId = applicationIds.platform;
            break;
          case "CustomApps":
            applicationId = applicationIds["wms-core-ceva"];
            break;
          default:
            applicationId = "";
        }
        this.axiosInstance.defaults.headers.common[
          "applicationid"
        ] = `${applicationId}`;
        this.axiosInstance.defaults.headers.common[
          "authorization"
        ] = `Bearer ${authToken}`;
        this.axiosInstance.defaults.headers.common["clientid"] = `${clientId}`;
      }
    } catch (error) {
      console.error(error);
    }
  }
  private async refreshAuthentication(): Promise<boolean> {
    try {
      const authenticationData = getFromStorage("authentication");
      const loginResponse = await this.axiosInstance.post(
        this.setBasePath(`${settings.Urls.Admin.Authorization}/login`, "Login"),
        {
          userName: authenticationData.userName,
          password: "",
          clientType: "web",
          grantType: "refreshToken",
          refreshToken: authenticationData.refreshToken,
          application: settings.ProductId,
        }
      );
      if (loginResponse.status === 200) {
        writeStorage("authentication", loginResponse.data);
        this.axiosInstance.defaults.headers.common[
          "authorization"
        ] = `Bearer ${loginResponse.data.authToken}`;
        this.axiosInstance.defaults.headers.common[
          "applicationid"
        ] = `${loginResponse.data.applicationId}`;
        this.axiosInstance.defaults.headers.common[
          "clientid"
        ] = `${loginResponse.data.clientId}`;
        return true;
      }
    } catch (error) {
      console.error(`RefreshAuthentication failed: ${error.message}`);
    }
    removeStorage("authentication");
    // Redirect to "/auth/login" and return false
    window.location.href = "/auth/login";
    return false;
  }
}

export const DcpAxiosContext = createContext<DcpAxiosService | null>(null);

interface DcpAxiosProviderProps {
  children: ReactNode;
}

export const DcpAxiosProvider = ({
  children,
}: DcpAxiosProviderProps): JSX.Element => {
  const dcpAxiosService = new DcpAxiosService();

  return (
    <DcpAxiosContext.Provider value={dcpAxiosService}>
      {children}
    </DcpAxiosContext.Provider>
  );
};

export function useDcpAxiosService(): DcpAxiosService {
  const dcpAxiosService = useContext(DcpAxiosContext);
  if (!dcpAxiosService) {
    console.error("dcpAxiosService must be used within a DcpAxiosProvider");
  }
  return dcpAxiosService!;
}
