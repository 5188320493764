import "./scss/picking-multi.scss";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import PageHeader from "~/shared/components/page-header/page-header";
import { ThemeContext } from "~/app";
import { InputText } from "primereact/inputtext";
import {
  GetPickingItems,
  InsertPicking,
  ListPickingSku as listPickingSkus,
  ListPickingWorktations as listPickingWorktations,
  UpdatePickingItemStatus,
} from "~/services/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "~/shared/components/dcp-button";
import { Axios } from "axios";
import { useToastContext } from "~/context/ToastContext";
import { useLanguageContext } from "~/context/LanguageContext";
import { compareNormalized } from "~/shared/helpers/utils";

export default function WMSCoreCevaPickingMulti() {
  const { currentTheme } = useContext(ThemeContext);
  const skuRef = useRef(null);
  const nfRef = useRef(null);
  const serialRef = useRef(null);
  const { showToast } = useToastContext();

  // Data
  const [workStations, setWorkStations] = useState<any[]>();
  const [selectedWorkstation, setSelectedWorkstation] = useState();
  const [sku, setSku] = useState("");
  const [nf, setNf] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [nextSku, setNextSku] = useState("");

  // View Controll
  const [fullScreen, setFullScreen] = useState(false);
  const [loadingWorkstations, setLoadingWorkstations] = useState(true);

  // Input Block
  const [nfBlocked, setNfBlocked] = useState(false);
  const [skuBlocked, setSkuBlocked] = useState(false);
  const [serialNumberBlocked, setSerialNumberBlocked] = useState(false);

  function headerActions() {
    return (
      <div className="icon" onClick={() => setFullScreen(!fullScreen)}>
        <Icon
          icon={fullScreen ? "minimize-01" : "maximize-01"}
          size={18}
          color={currentTheme.iconDefaultColor}
        />
      </div>
    );
  }

  async function readNf() {
    try {
      var axios = new Axios({});
      // const { data, status } = await axios.post(
      //   `http://localhost:9088/api/telnet/wms/core/ler-nf`,
      //   {
      //     workstation: selectedWorkstation,
      //     nf: nf,
      //   }
      // );

      // Mock Data
      const status = 200;
      const data = { firstSku: "CARTAO" };

      if (status === 200) {
        setNfBlocked(true);
        setSkuBlocked(false);
        setSerialNumberBlocked(false);
        setNextSku(data.firstSku);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadWorkStations() {
    try {
      var response = await listPickingWorktations();
      setWorkStations(response);
      if (response.length > 0 && !selectedWorkstation)
        setSelectedWorkstation(response[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function insert(e: React.FocusEvent<HTMLInputElement>) {
    try {
      e.stopPropagation();
      // submitPicking();
      resetInput();
    } catch (error) {
      console.error(error);
    }
  }

  function resetInput() {
    try {
      setSerialNumber("");
      setNf("");
      skuRef.current.focus();
    } catch (error) {
      console.error(error);
    }
  }

  // Telnet request
  async function validateLoginTelnet() {
    try {
      const payload = {
        speed: 4000,
        userName: "BERNASCONF",
        password: "v98SIICevb4359",
        wmsHostName: "matrix-wms-02U",
        wmsPort: "45908",
        nf: null,
        serial: null,
        sku: null,
        menuCode: "2313",
      };

      var axios = new Axios({});
      const response = await axios.post(
        `http://localhost:9088/api/telnet/wms/core/login`,
        payload
      );

      if (response.status === 200) {
        showToast({
          severity: "success",
          message: "Login realizado com sucesso.",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Initial values load
  useEffect(() => {
    async function load() {
      setLoadingWorkstations(true);
      await loadWorkStations();
      setLoadingWorkstations(false);
    }

    load();
  }, []);

  return (
    <div className={`picking-main-container ${fullScreen ? "fullscreen" : ""}`}>
      <div className="backdrop">
        <PageHeader
          title={
            <LanguageProvider id="dcp.side.menu.picking.cartao.wms.core" />
          }
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={undefined}
          actions={headerActions}
        ></PageHeader>
        <div className="content-container">
          <div className="fields-container">
            <Form>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.side.menu.estacao.wms.core" />
                  }
                >
                  <Dropdown
                    options={workStations}
                    loading={loadingWorkstations}
                    value={selectedWorkstation}
                    onChange={(e) => setSelectedWorkstation(e.value)}
                    filter
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="datasource.column.nf" />}
                >
                  <InputText
                    ref={nfRef}
                    value={nf}
                    onChange={(e) => setNf(e.target.value)}
                    disabled={nfBlocked}
                    autoFocus
                    onBlur={readNf}
                  ></InputText>
                </InputContainer>
              </FormRow>
              <h3>
                <LanguageProvider id="core.ceva.picking.next.sku" />: {nextSku}
              </h3>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.sku" />}>
                  <InputText
                    value={nf}
                    onChange={(e) => setNf(e.target.value)}
                    ref={skuRef}
                    disabled={nfBlocked}
                    autoFocus
                  ></InputText>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="devices.drones.serial" />}
                >
                  <InputText
                    value={serialNumber}
                    ref={serialRef}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    disabled={serialNumberBlocked}
                    onBlur={insert}
                  ></InputText>
                  {/* Fake input to prevent focus from moving out of the page */}
                  <input
                    style={{ width: "0", height: "0", border: "none" }}
                  ></input>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
        </div>
        <div className="footer-container">
          <Button appearance="secondary" onClick={() => validateLoginTelnet()}>
            Validar login WMS
          </Button>
          <Button
            appearance="secondary"
            onClick={() => {
              setNf("");
              setSerialNumber("");
            }}
          >
            <LanguageProvider id="dcp.platform.warehouse.clean.data" />
          </Button>
        </div>
      </div>
    </div>
  );
}
