import "./scss/nav-menu.scss";

import { useClickOutside, useResizeListener } from "primereact/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext, UserContext } from "~/app";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { useHotkeys } from "react-hotkeys-hook";
import { NavLink } from "react-router-dom";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import {
  getFromStorage,
  propertyFrommStorage,
  writeStorage,
} from "~/services/storage/storage-access";
import Logo from "../../theme/media/logos/duett-logo-light-green.svg";
import ProductIcon from "../components/icon-components/nav-product-icon";
import Icon from "../components/icons";
import LanguageProvider from "../components/language-provider";
import DocumentationModal from "../documentation-modal";
import NavTopSearch from "./nav-top-search";
import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";

const NavMenu = () => {
  const dcpAxiosService = useDcpAxiosService();
  const { user } = useContext(UserContext);
  const { currentTheme, theme, setTheme } = useContext(ThemeContext);
  const { currentLanguage, changeLanguage } = useLanguageContext();
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [visibleDocumentation, setVisibleDocumentation] = useState(false);
  const [displayProducSelectionPopUp, setDisplayProducSelectionPopUp] =
    useState(false);
  const [userProducts, setUserProducts] = useState([]);
  const [screendDimensions, setScreendDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = getFromStorage("isDarkMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const selectedLocalStorageLanguage = getFromStorage("currentLanguage");
  const clientWhiteLogoPath = propertyFrommStorage(
    "authentication",
    "whiteModeLogoPath"
  );
  const clientDarkLogoPath = propertyFrommStorage(
    "authentication",
    "darkModeLogoPath"
  );
  const clientLogoPath = isDarkMode ? clientDarkLogoPath : clientWhiteLogoPath;
  const showLogoPath = propertyFrommStorage("authentication", "showClientLogo");
  const languagesOptions = {
    ["en-us"]: <LanguageProvider id={"enUS"} />,
    ["es-es"]: <LanguageProvider id={"esES"} />,
    ["pt-br"]: <LanguageProvider id={"ptBr"} />,
    ["fr-fr"]: <LanguageProvider id={"frFR"} />,
  };

  // Refs
  const menuRef = useRef(null);
  const modalContentRef = useRef(null);
  const productMenuButtonRef = useRef();
  const productMenuRef = useRef();

  const menuItems = [
    {
      command: () => {},
      template: (item, options) => {
        return (
          <div className="user-menu-wrapper">
            <div className="user-identification">
              <div className="user-avatar">
                <Icon
                  icon={"user-01"}
                  size={"24px"}
                  className={"logout-icon"}
                />
              </div>
              <div className="user-info-wrapper">
                <div className="user-name">{user.userName}</div>
                <div className="user-email">{user.email}</div>
                <div className="user-domain">{user.domain}</div>
              </div>
            </div>
            <div className="keyboard-shortcut-btn">
              <a className="label" onClick={() => showDocumentation()}>
                <LanguageProvider id="gen.keyborad.shortcut" />
              </a>
            </div>
            <div className="theme-btn">
              <div className="theme-select">
                <LanguageProvider id="gen.theme" />
                <div
                  id="theme-selector-btn"
                  onClick={() => setIsDarkMode((prevMode) => !prevMode)}
                >
                  <div
                    className={`selector ${
                      isDarkMode ? "deactivated" : "lightModeActive"
                    }`}
                  >
                    <Icon
                      icon={"sun"}
                      size={"20px"}
                      color={theme === "dark" ? "#ffffff94" : "#4e5ba6"}
                    />
                  </div>
                  <div
                    className={`selector ${
                      isDarkMode ? "darkModeActive" : "deactivated"
                    }`}
                  >
                    <Icon
                      icon={"moon-01"}
                      size={"20px"}
                      color={theme === "dark" ? "#fff" : "#4e5ba6a2"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="language-btn">
              <div className="language-select">
                <LanguageProvider id="gen.language" />
                <div className="language-selector">
                  <Dropdown
                    className="language-dropdown"
                    value={
                      selectedLocalStorageLanguage
                        ? selectedLocalStorageLanguage
                        : currentLanguage
                    }
                    options={Object.keys(languagesOptions).map((key) => ({
                      name: languagesOptions[key],
                      code: key,
                    }))}
                    onChange={(e) => {
                      changeLanguage(e.target.value);
                      writeStorage("currentLanguage", e.target.value);
                    }}
                    optionLabel="name"
                    optionValue="code"
                  />
                </div>
              </div>
            </div>
            <NavLink to="/auth/logout" className="logout-btn">
              <p className="label">
                <LanguageProvider id="gen.quit" />
              </p>
            </NavLink>
          </div>
        );
      },
    },
  ];

  /* Hot Keys Control */
  useHotkeys("ctrl+k", (event) => {
    showSearch(true);
    event.preventDefault();
  });
  useHotkeys("f2", (event) => {
    setDisplayProducSelectionPopUp(!displayProducSelectionPopUp);
    event.preventDefault();
  });
  useHotkeys("f1", (event) => {
    showDocumentation(true);
    event.preventDefault();
  });
  useHotkeys("esc", (event) => {
    setDisplayProducSelectionPopUp(false);
    setVisibleSearch(false);
    setVisibleDocumentation(false);
    event.preventDefault();
  });

  /** Window resize event listener */
  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        setScreendDimensions({
          width: event.currentTarget.innerWidth,
          height: event.currentTarget.innerHeight,
        });
      },
    });

  function showSearch() {
    setVisibleSearch(true);
  }
  function showDocumentation() {
    setVisibleDocumentation(true);
  }
  function toggleProductMenu() {
    setDisplayProducSelectionPopUp(!displayProducSelectionPopUp);
  }
  async function loadUserProducts() {
    try {
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Admin.ApplicationClient}/list-user-products`,
        "Auth"
      );
      if (data.status) {
        setUserProducts(data.data.filter((x) => x.code != "platform"));
      }
    } catch (error) {
      console.error(error);
    }
  }
  useClickOutside(productMenuRef, () => {
    setDisplayProducSelectionPopUp(false);
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        setVisibleSearch(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleSearch]);
  useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);
  useEffect(() => {
    setDisplayProducSelectionPopUp(false);
  }, [screendDimensions]);

  useEffect(() => {
    loadUserProducts();
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
    writeStorage("isDarkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  return (
    <>
      <div className="nav-menu">
        <div className="product-menu-wrapper">
          <button
            ref={productMenuButtonRef}
            className="btn-product-menu"
            onClick={toggleProductMenu}
          >
            <Icon icon={"dots-grid"} color={currentTheme.primary} size={24} />
          </button>
          <div className="app-icon-container">
            <NavLink to="/">
              <img className="app-icon" src={Logo} />
            </NavLink>
          </div>
        </div>

        <div className="flex-spacer"></div>

        <div className="nav-menu-search">
          <span className="search-input p-input-icon-left">
            <div className="icon-search">
              <Icon icon={"search-md"} color="#98A2B3" size={20} />
            </div>
            <InputText
              className="p-inputtext-md"
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
              onClick={() => showSearch()}
            />
            <div className="tag-region">
              <span className="tag-hot-key">CTRL</span>
              <span className="tag-hot-key">K</span>
            </div>
          </span>
        </div>
        <div className="flex-spacer"></div>
        <div className="menus-container">
          <div className="context-menus">
            <a className="menu support">
              <Icon
                icon={"help-circle"}
                size={"32px"}
                className={"support-icon"}
                onClick={() => showDocumentation(true)}
              />
            </a>
            <NavLink to="/users" className="menu settings">
              <Icon
                icon={"settings-01"}
                size={"32px"}
                className={"settings-icon"}
              />
            </NavLink>
            {showLogoPath && (
              <a
                href={clientLogoPath}
                target="_blank"
                className="menu client-logo"
              >
                <img
                  src={clientLogoPath}
                  alt="Client Logo"
                  className="client-logo-img"
                />
              </a>
            )}
          </div>
          <div className="user-menu-container">
            <div
              className="menu user"
              onClick={(event) => menuRef.current.toggle(event)}
            >
              <Icon icon={"user-01"} size={"24px"} className={"logout-icon"} />
            </div>
          </div>
          <Menu
            ref={menuRef}
            model={menuItems}
            popup
            className="user-menu"
            appendTo="self"
          />
        </div>
      </div>
      <Dialog
        visible={visibleSearch}
        header={LanguageProvider({ id: "gen.global.search" })}
        className="remove-header-dialog"
        position={"top"}
        style={{ width: "100%", maxWidth: "600px" }}
        onHide={() => setVisibleSearch(false)}
      >
        <NavTopSearch />
      </Dialog>

      <Dialog
        visible={visibleDocumentation}
        header={LanguageProvider({ id: "gen.shortcuts" })}
        position={"center"}
        style={{ width: "100%", maxWidth: "800px" }}
        onHide={() => setVisibleDocumentation(false)}
        modal
      >
        <DocumentationModal />
      </Dialog>
      {displayProducSelectionPopUp && (
        <div
          ref={productMenuRef}
          className="nav-menu-product-menu"
          style={{
            top:
              productMenuButtonRef.current.offsetTop +
              productMenuButtonRef.current.offsetHeight,
            left: productMenuButtonRef.current.offsetLeft,
          }}
        >
          <div className="nav-menu-product-menu-header">
            <span className="nav-menu-product-menu-header-title">
              <LanguageProvider
                id={"dcp.nav.menu.product.selection.popup.title"}
              />
            </span>
          </div>
          <div className="nav-menu-product-menu-content">
            {userProducts &&
              userProducts.map((product, productIndex) => {
                return (
                  <NavLink
                    to={`/${product.instanceCode}/home/${product.code}`}
                    reloadDocument
                    key={`product-menu-item-${productIndex}`}
                    className={`product-option-label`}
                  >
                    <ProductIcon productCode={product.code} />
                    {product.productDescription}
                  </NavLink>
                );
              })}
          </div>
          <div className="nav-menu-product-menu-footer">
            <div className="items">
              <div className="item">
                <span className="tag">Esc</span>{" "}
                <LanguageProvider id={"dpc.gen.leave.key.desc"} />
              </div>
              <div className="item">
                <span className="tag">F2</span>{" "}
                <LanguageProvider id={"gen.shortcut"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavMenu;
