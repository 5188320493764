import "./scss/identification-print.scss";

import LanguageProvider from "~/shared/components/language-provider";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import { Button } from "~/shared/components/dcp-button";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import QRCode from "react-qr-code";
import JsBarcode from "jsbarcode";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import ReactToPrint from "react-to-print";
import HomeProduct from "~/shared/components/home-product";
import imgEmptyForm from "~/theme/media/assets/emptyInbox.svg";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { MassivePrintDialog } from "./components/massive-print-dialog";
import { useToastContext } from "~/context/ToastContext";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import { ThemeContext, UserContext } from "~/app";
import DcpDeniedAccess from "~/shared/components/dcp-denied-access";
import axios from "axios";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import Icon from "~/shared/components/icons";

const IdentificationPrint = () => {
  const { userRoles } = useContext(UserContext);
  const { currentLanguage } = useLanguageContext();
  const { currentTheme } = useContext(ThemeContext);
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [displayClearDataDialog, setDisplayClearDataDialog] = useState(false);
  const [openMassivePrintDialog, setOpenMassivePrintDialog] = useState(false);
  const [skuOptionValues, setSkuOptionValues] = useState([]);
  const [isFormFilled, setIsFormFilled] = useState(true);
  const [massivePrintLoading, setMassivePrintLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [loadingSavePrintForm, setLoadingSavePrintForm] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const barcodeRef = useRef(null);
  const printRef = useRef();

  interface FormValues {
    sku: {
      id: string;
      label: string;
    };
    code: string;
  }

  const validatePrintFieldsForm = (values: FormValues) => {
    const errors = {} as {
      sku?: string;
      code?: string;
    };
    if (values.sku.id === "" || values.sku.id === null) {
      errors.sku = LanguageProviderWithoutContext({
        id: "gen.message.print.document.prints.required",
        currentLanguage,
      });
    }
    errors.code = values.code === "" ? "Código é obrigatório" : "";

    return errors;
  };

  const formikPrinter = useFormik({
    initialValues: {
      sku: {
        id: "",
        label: "",
      },
      code: "",
    },
    enableReinitialize: true,
    validate: validatePrintFieldsForm,
    onSubmit: async (values) => {
      try {
        setLoadingSavePrintForm(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSavePrintForm(false);
        clearForm();
      }
    },
  });

  const IsFormValid = (name) => {
    const fieldNameParts = name.split(".");
    const fieldError = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.errors
    );
    const fieldTouched = fieldNameParts.reduce(
      (acc, key) => acc?.[key],
      formikPrinter.touched
    );
    return !!(fieldError && fieldTouched);
  };

  const clearForm = () => {
    formikPrinter.setValues({
      sku: {
        id: "",
        label: "",
      },
      code: "",
    });
  };

  const handleHtmlToImage = async () => {
    try {
      const node = printRef.current;
      const dataUrl = await toPng(node);
      const base64Image = dataUrl.split(",")[1];

      return base64Image;
    } catch (error) {
      console.error("Failed to convert image: ", error);
    }
  };

  const convertToPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });
    try {
      const pngDataUrl = await toPng(printRef.current);
      const imgWidth = 290;
      const imgProps = doc.getImageProperties(pngDataUrl);
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
      await doc.addImage(pngDataUrl, "PNG", 0, 0, imgWidth, imgHeight);

      const dataUrl = await doc.output("datauristring");

      return dataUrl;
    } catch (error) {
      console.error("Erro ao converter para PDF: ", error);
    }
  };

  const handleChangeSkuDropdown = async (e) => {
    const selectedOptionName = skuOptionValues.find(
      (option) => option.value === e.value
    );
    formikPrinter.setFieldValue("sku", {
      id: e.value,
      label: selectedOptionName.name,
    });
  };

  const handleClearData = () => {
    if (formikPrinter.values) {
      clearForm();
    }
    setDisplayClearDataDialog(false);
    setIsFormFilled(false);
  };

  const handleMassivePrint = async (printData) => {
    let imagesBase64List = [];
    try {
      setMassivePrintLoading(true);

      // Sort the interfacePrintData alphabetically by item name
      const sortedPrintData = [...printData.interfacePrintData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      for (const item of sortedPrintData) {
        const selectedOptionName = skuOptionValues.find(
          (option) => option.value === item.name
        );

        formikPrinter.setFieldValue("sku", {
          id: selectedOptionName.value,
          label: selectedOptionName.name,
        });
        formikPrinter.setFieldValue("code", item.value);

        await new Promise((resolve) => setTimeout(resolve, 800));

        let base64 = await convertToPdf();

        imagesBase64List.push({
          type: "pdf",
          encoded: true,
          payload: base64,
        });

        var massiveDataToPrint = {
          printer: {
            name: printData.printerValues.name,
            ip: printData.printerValues.ip,
            port: printData.printerValues.port,
          },
          documents: imagesBase64List,
        };

        const { status, data } = await axios.post(
          printData.printDriverValueInSettings + "/api/printer/print",
          massiveDataToPrint
        );

        if (status) {
          showToast({
            message: LanguageProviderWithoutContext({
              id: "gen.massive.identification.print.done.success",
              currentLanguage,
            }),
            severity: "success",
          });
        } else {
          showToast({
            message: <LanguageProvider id={"unhandled.error"} />,
            severity: "error",
          });
        }

        imagesBase64List = [];
        formikPrinter.resetForm();
      }

      setOpenMassivePrintDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setMassivePrintLoading(false);
    }
  };

  useEffect(() => {
    async function loadSavePrintersDatabaseDropdownFields() {
      try {
        setLoadingRegister(true);
        const { status, data } = await dcpAxiosService.get(
          settings.Urls.Rest.Inventory + "/list-sku",
          "Inventory"
        );
        if (status === 200 && data.data) {
          const skusOptionContent = data.data.connectionRows.map((row) => ({
            name: row.name,
            pattern: row.pattern,
            value: row.code,
          }));
          setSkuOptionValues(skusOptionContent);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingRegister(false);
      }
    }
    loadSavePrintersDatabaseDropdownFields();
  }, []);

  useEffect(() => {
    const { sku, code } = formikPrinter.values;
    if (sku.id.length > 0 || code.length > 0) {
      setIsFormFilled(false);
      setShowForm(true);
    } else {
      setIsFormFilled(true);
      setShowForm(false);
    }
  }, [formikPrinter.values]);

  useEffect(() => {
    if (formikPrinter.values.code && barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, formikPrinter.values.code, {
          format: "CODE128",
          displayValue: false,
          width: 2,
          height: 150,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [formikPrinter.values.code]);

  return (
    <>
      {!userRoles.administrator &&
      !userRoles.inventoryManager &&
      !userRoles.receiver ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: currentTheme.systemBackground,
            color: currentTheme.textPrimary,
            fontSize: "24px",
          }}
        >
          <DcpDeniedAccess />
        </div>
      ) : (
        <div
          className={`print-identification-main-container ${
            fullScreen ? "fullscreen" : null
          }`}
        >
          <div className="backdrop">
            <div className="print-header-wrapper">
              <div className="container">
                <div className="header">
                  <div className="title-wrapper">
                    <span className="header-message">
                      <span className="title">
                        <LanguageProvider id={"product.print.menu.print"} />
                      </span>
                    </span>
                  </div>
                  <div
                    className="icon"
                    onClick={() => setFullScreen(!fullScreen)}
                  >
                    <Icon
                      icon={fullScreen ? "minimize-01" : "maximize-01"}
                      size={18}
                      color={currentTheme.iconDefaultColor}
                    />
                  </div>
                </div>
                <div className="containerTables">
                  <div className="tableLeft">
                    <div className="entry-settings-side-fields">
                      <div className="entry-form-container">
                        <Form className="entry-settings-form">
                          <FormRow>
                            <InputContainer className="identification-print-dropdown">
                              <span>
                                <LanguageProvider
                                  id={
                                    "dcp.print.identification.print.orientation"
                                  }
                                />
                              </span>
                            </InputContainer>
                          </FormRow>
                          <FormRow className="entry-form-row">
                            <InputContainer
                              className="identification-print-dropdown"
                              label={LanguageProviderWithoutContext({
                                id: "dcp.print.identification.sku.label",
                                currentLanguage,
                              })}
                            >
                              <Dropdown
                                id="printer"
                                filter
                                placeholder={LanguageProviderWithoutContext({
                                  id: "dcp.print.identification.sku.dropdown",
                                  currentLanguage,
                                })}
                                className={[
                                  "print-document-dropdown",
                                  classNames({
                                    "p-invalid": IsFormValid("printer"),
                                  }),
                                ].join(" ")}
                                options={skuOptionValues}
                                value={formikPrinter.values.sku.id}
                                title={formikPrinter.values.sku.label}
                                onChange={(e) => handleChangeSkuDropdown(e)}
                                optionLabel="pattern"
                                optionValue="value"
                                loading={loadingRegister}
                              />
                            </InputContainer>
                          </FormRow>
                          <FormRow className="entry-form-row">
                            <InputContainer
                              className="identification-print-dropdown"
                              label={<LanguageProvider id={"gen.code"} />}
                            >
                              <InputText
                                id="code"
                                className={[
                                  "print-document-dropdown",
                                  classNames({
                                    "p-invalid": IsFormValid("printer"),
                                  }),
                                ].join(" ")}
                                value={formikPrinter.values.code}
                                onChange={(e) => {
                                  formikPrinter.setFieldValue(
                                    "code",
                                    e.target.value
                                  );
                                }}
                              />
                            </InputContainer>
                          </FormRow>
                        </Form>
                      </div>
                      {formikPrinter.values.sku.id &&
                      formikPrinter.values.code ? (
                        <div className="entry-template-container">
                          <div className="entry-form-template" ref={printRef}>
                            <style type="text/css" media="print">
                              {" @page { size: landscape; } "}
                            </style>
                            <div className="entry-form-template-codes-wrapper">
                              <div className="entry-form-template-qr-code">
                                {formikPrinter.values.code && (
                                  <QRCode
                                    size={350}
                                    value={formikPrinter.values.code}
                                    viewBox={`0 0 256 256`}
                                    level="H"
                                  />
                                )}
                              </div>
                              <div className="entry-form-template-barcode">
                                <svg ref={barcodeRef} />
                                <span className="barcode-value">
                                  {formikPrinter.values.code}
                                </span>
                              </div>
                            </div>
                            <div className="entry-form-template-code"></div>
                            <div className="entry-form-template-sku-value">
                              <span>{formikPrinter.values.sku.id}</span>
                            </div>
                            <div className="entry-form-template-sku-label">
                              <span>{formikPrinter.values.sku.label}</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="home-print-identification-layout">
                          <HomeProduct
                            title={
                              <LanguageProvider
                                id={
                                  "dcp.print.document.identification.print.button"
                                }
                              />
                            }
                            bodyMessage={
                              <LanguageProvider
                                id={"dcp.print.identification.print.message"}
                              />
                            }
                            image={imgEmptyForm}
                            isVisible={true}
                            showButton={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="entryFooter">
                  <div className="entry-manage-print-massive-wrapper">
                    <Button
                      id="massivePrint"
                      className="p-button p-button-text-plain clear-data"
                      onClick={() => setOpenMassivePrintDialog(true)}
                      loading={massivePrintLoading}
                    >
                      <LanguageProvider
                        id={"dcp.platform.inventory.massive.print"}
                      />
                    </Button>
                  </div>
                  <div className="entry-manage-data-buttons">
                    <Button
                      id="clearData"
                      className="p-button p-button-text-plain clear-data"
                      onClick={() => setDisplayClearDataDialog(true)}
                      disabled={isFormFilled}
                    >
                      <LanguageProvider
                        id={"dcp.platform.warehouse.clean.data"}
                      />
                    </Button>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          id="print-template"
                          appearance="primary"
                          loading={loadingSavePrintForm}
                          disabled={
                            !formikPrinter.values.sku.id ||
                            !formikPrinter.values.code
                          }
                        >
                          <LanguageProvider
                            id={"dcp.print.identification.print.button"}
                          />
                        </Button>
                      )}
                      content={() => printRef.current}
                      pageStyle={`@import url('./scss/identification-print.scss');`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalConfirmation
        isOpen={displayClearDataDialog}
        isDelete={true}
        modalTitle={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data"} />
        }
        bodyMessage={
          <LanguageProvider id={"dcp.platform.warehouse.clean.data.message"} />
        }
        onConfirm={handleClearData}
        onCancel={() => setDisplayClearDataDialog(false)}
      />
      <MassivePrintDialog
        isOpen={openMassivePrintDialog}
        onClose={() => setOpenMassivePrintDialog(false)}
        massivePrintDataSelected={handleMassivePrint}
      />
      <Dialog
        header={<LanguageProvider id={"gen.wait"} />}
        visible={massivePrintLoading}
        modal
        closable={false}
        onHide={() => {}}
      >
        <div className="flex flex-column align-items-center">
          <p>
            <LanguageProvider id={"identification.print.wait.description"} />
          </p>
          <ProgressBar mode="indeterminate" style={{ width: "100%" }} />
        </div>
      </Dialog>
    </>
  );
};

export default IdentificationPrint;
