import "./scss/auth-side-menu.scss";

import { PanelMenu } from "primereact/panelmenu";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../components/icons";
import LanguageProvider from "../components/language-provider";

const AuthSideMenu = () => {
  const [menuItems, setMenuItems] = useState([
    {
      key: "auth-settings",
      label: LanguageProvider({ id: "dcp.auth.side.menu.settings" }),
      expanded: true,
      items: [
        {
          key: "auth-settings-users",
          url: "users",
          label: LanguageProvider({ id: "dcp.auth.side.menu.settings.users" }),
          template: DcpNavMenuItemTemplate,
        },
        {
          key: "auth-settings-integrations",
          url: "integrations",
          label: LanguageProvider({
            id: "dcp.auth.side.menu.settings.integrations",
          }),
          template: DcpNavMenuItemTemplate,
        },
        // {
        //   key: "auth-settings-grafana",
        //   url: "grafana",
        //   label: LanguageProvider({
        //     id: "dcp.auth.side.menu.settings.grafana",
        //   }),
        //   template: DcpNavMenuItemTemplate,
        // },
        {
          key: "auth-settings-data-management",
          url: "data-management",
          label: LanguageProvider({
            id: "dcp.auth.side.menu.settings.data.management",
          }),
          template: DcpNavMenuItemTemplate,
        },
        {
          key: "auth-settings-gpt-ai",
          url: "gpt-ai",
          label: LanguageProvider({
            id: "dcp.auth.side.menu.settings.gpt-ai",
          }),
          template: DcpNavMenuItemTemplate,
        },
      ],
      template: DcpNavMenuItemTemplate,
    },
  ]);

  return (
    <div className="auth-side-menu">
      <div className="side-menu-container">
        <div className="navigation">
          <PanelMenu className="dcp-panel-menu" model={menuItems} multiple />
        </div>
      </div>
    </div>
  );
};

const DcpNavMenuItemTemplate = (item, options) => {
  return (
    <NavLink
      to={item.url}
      className={(isActive, isPending, isTransitioning) =>
        [
          options.className,
          isActive ? "active" : "",
          isPending ? "pending" : "",
          isTransitioning ? "transitioning" : "",
        ].join(" ")
      }
      role="menuitem"
      onClick={options.onClick}
    >
      {item.items && (!item.iconPosition || item.iconPosition === "start") && (
        <span className={options.submenuIconClassName} />
      )}
      {item.icon && (
        <Icon className={options.iconClassName} icon={item.icon} size={16} />
      )}
      <span className={`dcp-menu-item-label ${options.labelClassName}`}>
        {item.label}
      </span>
      {item.items && item.iconPosition === "end" && (
        <span className={options.submenuIconClassName} />
      )}
    </NavLink>
  );
};

export default AuthSideMenu;
