import { Dropdown } from "primereact/dropdown";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProvider from "~/shared/components/language-provider";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";

export function LastDaysDropwdown({ config, updateValue = function () {} }) {
  const options = ["7", "14", "30", "90"];

  return (
    <Dropdown
      value={config.value?.toString()}
      options={options}
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
