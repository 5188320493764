import "../scss/storage-subgroup.scss";
import React, { useEffect, useState } from "react";
import LanguageProvider from "../language-provider";
import settings from "~/services/settings.json";
import { Message } from "primereact/message";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export function StorageSubgroup() {
  const dcpAxiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  const [warningMessageVisibility, setWarningMessageVisibility] =
    useState(false);
  const [storageData, setStorageData] = useState(null);
  const [usedStoragePercentValue, setUsedStoragePercentValue] = useState(0);

  useEffect(() => {
    const getStorageData = async () => {
      if (applicationClientId) {
        try {
          const { data, status } = await dcpAxiosService.get(
            `${settings.Urls.Rest.dashboardStorage}/get-storage-use`,
            "Platform",
            {
              params: { applicationClientId: applicationClientId },
            }
          );
          if (status === 200) {
            setStorageData(data.data);
            const usedStoragePercent = (
              (data.data.used / data.data.hired) *
              100
            ).toFixed(0);
            setUsedStoragePercentValue(usedStoragePercent);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStorageData();
  }, [applicationClientId, dcpAxiosService]);

  useEffect(() => {
    if (usedStoragePercentValue >= 90) {
      setWarningMessageVisibility(true);
    }
  }, [usedStoragePercentValue]);

  return (
    <div className="subgroup-container">
      <p>
        <LanguageProvider id="inventory-storage-fields.description" />
      </p>
      <div className="subgroup-storage-container">
        {usedStoragePercentValue >= 90 && warningMessageVisibility && (
          <div className="storage-warning">
            <Message
              severity="warn"
              content={
                <div className="message-content">
                  <i className="pi pi-exclamation-triangle icon" />
                  <span className="message-text">
                    <strong>
                      <LanguageProvider
                        id={"setting.platform.storage.used.warning"}
                      />
                    </strong>{" "}
                    <LanguageProvider
                      id={"setting.platform.storage.used.warning.description"}
                    />
                  </span>
                  <button
                    className="close-button"
                    onClick={() => setWarningMessageVisibility(false)}
                  >
                    <i className="pi pi-times" />
                  </button>
                </div>
              }
            />
          </div>
        )}
        <span className="storage-info-title">
          <LanguageProvider id={"setting.platform.storage.cloud.space.title"} />
        </span>
        <div className="storage-data-wrapper">
          <div className="info">
            <span className="info-used">{storageData?.used.toFixed(2)} GB</span>
            <span className="info-hired">
              <LanguageProvider id={"gen.message.of"} />{" "}
              {storageData?.hired.toFixed(2)} GB
            </span>
          </div>
          <div className="storage-line-wrapper">
            <div className="progression-storage-bar">
              <div
                className="progression-storage-bar-filled"
                style={{
                  width: `${
                    usedStoragePercentValue > 100
                      ? "100"
                      : usedStoragePercentValue.toString()
                  }%`,
                  backgroundColor:
                    usedStoragePercentValue >= 90 ? "red" : "blue",
                }}
              ></div>
            </div>
          </div>
          <span className="storage-hired-info">
            {usedStoragePercentValue > 100 ? 100 : usedStoragePercentValue}%{" "}
            <LanguageProvider id={"setting.platform.storage.used.message"} />
          </span>
        </div>
      </div>
    </div>
  );
}
