import { Dialog } from "primereact/dialog";
import "../scss/historic-data-upload.scss";
import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import {
  deleteDatabaseItemsFromUploadedFile,
  getHistoricUploadedFilesByDatabaseId,
} from "~/services/api";
import { Column } from "primereact/column";
import LanguageProvider from "~/shared/components/language-provider";
import Icon from "~/shared/components/icons";
import { ThemeContext } from "~/app";
import ModalConfirmation from "~/shared/components/modal-confirmation";

interface HistoricUploadDataProps {
  id: number;
  totalDatabaseItemsUploaded: number;
  fileUploadedTitle: string;
  createdAt: string;
  userName: string;
}

export const HistoricUploadData = ({
  visible,
  idDatabase,
  onHide,
  onFinish,
}) => {
  const { currentTheme } = useContext(ThemeContext);
  const [historicUploadedFilesList, setHistoricUploadedFilesList] = useState<
    HistoricUploadDataProps[]
  >([]);
  const [
    displayConfirmFileRegistersDeletion,
    setDisplayConfirmFileRegistersDeletion,
  ] = useState<boolean>(false);
  const [manageFileRegistersId, setManageFileRegistersId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const HandleDeleteFileRegisters = async (confirmation) => {
    if (confirmation) {
      try {
        const response = await deleteDatabaseItemsFromUploadedFile(
          idDatabase,
          manageFileRegistersId
        );

        if (response) {
          await LoadGridData();
          setDisplayConfirmFileRegistersDeletion(false);
          onFinish(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setDisplayConfirmFileRegistersDeletion(false);
      setManageFileRegistersId(0);
    }
  };

  const OpenModalDeleteFileRegister = (model) => {
    console.log(model);

    setManageFileRegistersId(model.id);
    setDisplayConfirmFileRegistersDeletion(true);
  };

  const dialogHeader = () => {
    return (
      <>
        <span className="upload-history-data-title">Historico de Upload</span>
      </>
    );
  };

  const LoadGridData = async () => {
    try {
      setIsLoading(true);

      const getList = await getHistoricUploadedFilesByDatabaseId(idDatabase);

      if (getList && Array.isArray(getList)) {
        const filteredList = getList.map((item: any) => ({
          id: item.id,
          totalDatabaseItemsUploaded: item.totalDatabaseItemsUploaded,
          fileUploadedTitle: item.fileUploadedTitle,
          createdAt: item.createdAt,
          userName: item.userName,
        }));

        setHistoricUploadedFilesList(filteredList);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchHistoricUploadedFilesList = async () => {
      await LoadGridData();
    };
    fetchHistoricUploadedFilesList();
  }, [visible]);

  return (
    <>
      <Dialog
        visible={visible}
        onHide={onHide}
        header={dialogHeader}
        headerStyle={{
          padding: "30px 40px",
          borderBottom: "1px solid var(--systemBackground)",
        }}
        contentStyle={{
          display: "flex",
          width: "900px",
          height: "520px",
          padding: "0",
        }}
      >
        <div className="historic-data-upload-container">
          <div className="historic-data-table-wrapper">
            <span>
              Escolha quais registros deseja excluir do database atual.
            </span>
            <div className="historic-upload-table">
              <DataTable
                value={historicUploadedFilesList}
                rows={5}
                removableSort
                paginator
                loading={isLoading}
                key={historicUploadedFilesList.length}
                emptyMessage={"Nenhum registro encontrado"}
              >
                <Column field="fileUploadedTitle" header="Nome do Arquivo" />
                <Column
                  filter
                  showFilterOperator={false}
                  sortable
                  field="userName"
                  header="Usuário"
                />
                <Column
                  header={<LanguageProvider id={"gen.create.at"} />}
                  headerClassName="custom-header-created-at-class"
                  field="createdAt"
                  body={(rowData) => {
                    const createdAtDate = new Date(rowData.createdAt);
                    const formattedDate = createdAtDate.toLocaleDateString();
                    const formattedTime = createdAtDate.toLocaleTimeString();

                    return (
                      <>
                        <div>
                          {formattedDate} {formattedTime}
                        </div>
                      </>
                    );
                  }}
                  filter
                  sortable
                />
                <Column
                  field="totalDatabaseItemsUploaded"
                  header="Total de Registros"
                  filter
                  showFilterOperator={false}
                  sortable
                />
                <Column
                  header={null}
                  headerClassName="custom-header-actions-class"
                  key={"actions"}
                  body={(rowData) => {
                    return (
                      <div className="column-wrapper">
                        <div className="column-actions">
                          <div className="icon-wrapper">
                            <Icon
                              icon={"trash-02"}
                              size={20}
                              color={currentTheme.tableIconColor}
                              onClick={() =>
                                OpenModalDeleteFileRegister(rowData)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </Dialog>
      <ModalConfirmation
        isOpen={displayConfirmFileRegistersDeletion}
        isDelete={true}
        modalTitle={
          <LanguageProvider
            id={"gen.confirm.database.uploaded.registers.delete"}
          />
        }
        bodyMessage={
          <LanguageProvider
            id={"gen.message.database.uploaded.file.registers"}
          />
        }
        onConfirm={HandleDeleteFileRegisters}
        onCancel={() => setDisplayConfirmFileRegistersDeletion(false)}
      />
    </>
  );
};
