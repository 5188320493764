import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function InsertPicking(model) {
  try {
    var { data, status } = await axiosService().post(
      settings.Urls.Rest.Picking + "/insert",
      model,
      "CustomApps"
    );

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetPickingItems(lastHours: number) {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.Picking + "/get",
      "CustomApps",
      { params: { lastHours: lastHours } }
    );

    if (status === 200 && Array.isArray(data.data)) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ListPickingSku() {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.Picking + "/list-skus",
      "CustomApps"
    );

    if (status === 200 && Array.isArray(data.data)) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function ListPickingWorktations() {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.Picking + "/list-workstations",
      "CustomApps"
    );

    if (status === 200 && Array.isArray(data.data)) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

// export async function GetLastUnprocessedPicking() {
//   try {
//     var { data, status } = await axiosService().get(
//       settings.Urls.Rest.Picking + "/unprocessed",
//       "CustomApps"
//     );

//     if (status === 200 && Array.isArray(data.data)) {
//       return data.data;
//     } else {
//       return [];
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

export async function UpdatePickingItemStatus(model) {
  try {
    var { data, status } = await axiosService().post(
      settings.Urls.Rest.Picking + "/update-status",
      model,
      "CustomApps"
    );

    if (status === 200) {
      return data.data;
    }
  } catch (error) {
    console.error(error);
  }
}
