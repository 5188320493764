import "./scss/picking-cartao.scss";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import PageHeader from "~/shared/components/page-header/page-header";
import { ThemeContext } from "~/app";
import { InputText } from "primereact/inputtext";
import {
  GetPickingItems,
  InsertPicking,
  ListPickingSku as listPickingSkus,
  ListPickingWorktations as listPickingWorktations,
  UpdatePickingItemStatus,
} from "~/services/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "~/shared/components/dcp-button";
import { Axios } from "axios";
import { useToastContext } from "~/context/ToastContext";
import { useLanguageContext } from "~/context/LanguageContext";
import { compareNormalized } from "~/shared/helpers/utils";

export default function WMSCoreCevaPickingCartao() {
  const { currentTheme } = useContext(ThemeContext);
  const focusRef = useRef(null);
  const { showToast } = useToastContext();

  // Data
  const [workStations, setWorkStations] = useState<any[]>();
  const [skus, setSkus] = useState<any[]>();
  const [selectedSku, setSelectedSku] = useState("CARTAO");
  const [selectedWorkstation, setSelectedWorkstation] = useState();
  const [items, setItems] = useState([]);
  const [nf, setNf] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [lastHours, setLastHours] = useState(1);
  const [itemListFilterQuery, setItemListFilterQuery] = useState("");

  // View Controll
  const [fullScreen, setFullScreen] = useState(false);

  // Loading Indicators
  const [loadinOptions, setLoadinOptions] = useState(true);
  const [loadingItems, setLoadingItems] = useState(true);

  function headerActions() {
    return (
      <div className="icon" onClick={() => setFullScreen(!fullScreen)}>
        <Icon
          icon={fullScreen ? "minimize-01" : "maximize-01"}
          size={18}
          color={currentTheme.iconDefaultColor}
        />
      </div>
    );
  }

  async function loadSkus() {
    try {
      var response = await listPickingSkus();
      setSkus(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadWorkStations() {
    try {
      var response = await listPickingWorktations();
      setWorkStations(response);
      if (response.length > 0 && !selectedWorkstation)
        setSelectedWorkstation(response[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function insert(e: React.FocusEvent<HTMLInputElement>) {
    try {
      if (nf.length < 1 || serialNumber.length < 1) return;
      e.stopPropagation();
      submitPicking();
      resetInput();
    } catch (error) {
      console.error(error);
    }
  }

  async function submitPicking() {
    try {
      var payload = {
        id: 0,
        serialNumber: serialNumber,
        nf: nf,
        sku: selectedSku,
        userName: "admin",
        eventDate: new Date(),
        codigoEstacao: selectedWorkstation,
        log: "",
        status: 0,
      };

      const insertResponse = await InsertPicking(payload);

      if (insertResponse.status) {
        showToast({
          severity: "success",
          message: `Serial adicionado: ${serialNumber}`,
        });
      } else {
        showToast({
          severity: "error",
          message: insertResponse.responseCode,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function resetInput() {
    setLoadingItems(true);
    try {
      setSerialNumber("");
      setNf("");
      focusRef.current.focus();
    } catch (error) {
      console.error(error);
    }
    setLoadingItems(false);
  }

  async function loadItems(_lastHour?: number) {
    setLoadingItems(true);
    try {
      const _items = await GetPickingItems(_lastHour ?? lastHours);
      setItems(_items);
    } catch (error) {
      console.error(error);
    }
    setLoadingItems(false);
  }

  async function sendToWms() {
    try {
      var _items = await GetPickingItems(lastHours);
      const unprocessed = _items.filter((x) => x.status === 0);
      for (const item of unprocessed) {
        const response = await telnetApi("picking");
        if (response.status) {
          const insertResponse = await UpdatePickingItemStatus({
            id: item.id,
            status: response.status ? 1 : 2,
            log: response.log,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Telnet request
  async function telnetApi(endpoint: string) {
    try {
      if (endpoint === "login") {
        showToast({
          severity: "success",
          message: "Login realizado com sucesso.",
        });
      }

      const payload = {
        speed: 4000,
        userName: "BERNASCONF",
        password: "v98SIICevb4359",
        wmsHostName: "matrix-wms-02U",
        wmsPort: "45908",
        nf: nf,
        serial: serialNumber,
        sku: selectedSku,
        menuCode: "2313",
      };

      var axios = new Axios({});
      const response = await axios.post(
        `http://localhost:9088/api/telnet/wms/core/${endpoint}`,
        payload
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function accordionHeaderTemplate(item: any) {
    const statusColor = ["#FFF2CC", "#D5E8D4", "#F8CECC"];

    return (
      <div className="accordionHeaderTemplate">
        <span className="serial">
          <LanguageProvider id="devices.drones.serial" />: {item.serialNumber}
        </span>
        <span className="date">
          {new Date(item.eventDate).toLocaleString()}
        </span>
        <div
          className="status"
          style={{ background: statusColor[item.status] }}
        ></div>
      </div>
    );
  }

  // Items refresh load
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(loadItems, 10000);
    return () => clearInterval(intervalId);
  }, []);

  // Telnet processing
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(sendToWms, 15000);
    return () => clearInterval(intervalId);
  }, []);

  // Initial values load
  useEffect(() => {
    async function load() {
      setLoadinOptions(true);
      await loadSkus();
      await loadWorkStations();
      await loadItems();
      setLoadinOptions(false);
    }

    load();
  }, []);

  return (
    <div className={`picking-main-container ${fullScreen ? "fullscreen" : ""}`}>
      <div className="backdrop">
        <PageHeader
          title={
            <LanguageProvider id="dcp.side.menu.picking.cartao.wms.core" />
          }
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={undefined}
          actions={headerActions}
        ></PageHeader>
        <div className="content-container">
          <div className="input-section">
            <Form>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.side.menu.estacao.wms.core" />
                  }
                >
                  <Dropdown
                    options={workStations}
                    loading={loadinOptions}
                    value={selectedWorkstation}
                    onChange={(e) => setSelectedWorkstation(e.value)}
                    filter
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.sku" />}>
                  <Dropdown
                    options={skus}
                    loading={loadinOptions}
                    value={selectedSku}
                    disabled={true}
                    onChange={(e) => setSelectedSku(e.value)}
                    filter
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              <h3>Processo Leitura</h3>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="datasource.column.nf" />}
                >
                  <InputText
                    value={nf}
                    onChange={(e) => setNf(e.target.value)}
                    ref={focusRef}
                    disabled={!selectedSku || !selectedWorkstation}
                    autoFocus
                  ></InputText>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="devices.drones.serial" />}
                >
                  <InputText
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    disabled={!selectedSku || !selectedWorkstation}
                    onBlur={insert}
                  ></InputText>
                  {/* Fake input to prevent focus from moving out of the page */}
                  <input
                    style={{ width: "0", height: "0", border: "none" }}
                  ></input>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
          <div className="view-section">
            <div className="search-bar">
              <Form>
                <FormRow>
                  <InputContainer
                    className="bigger-input"
                    label={<LanguageProvider id="gen.research" />}
                  >
                    <InputText
                      value={itemListFilterQuery}
                      onChange={(e) => setItemListFilterQuery(e.target.value)}
                    ></InputText>
                  </InputContainer>
                  <InputContainer label={<LanguageProvider id="Horas" />}>
                    <Dropdown
                      options={[1, 2, 6, 12, 24]}
                      value={lastHours}
                      onChange={(e) => {
                        setLastHours(e.value);
                        loadItems(e.value);
                      }}
                    ></Dropdown>
                  </InputContainer>
                </FormRow>
              </Form>
            </div>
            <div className="item-list">
              <div className="items">
                <Accordion>
                  {items.map((item) => {
                    // Apply search filter
                    if (itemListFilterQuery.length > 0) {
                      const searchConcatValue = `${item.serialNumber} ${item.nf} ${item.sku} ${item.log}`;
                      if (
                        !compareNormalized(
                          searchConcatValue,
                          itemListFilterQuery
                        )
                      )
                        return null;
                    }

                    return (
                      <AccordionTab
                        headerTemplate={() => accordionHeaderTemplate(item)}
                      >
                        <Form>
                          <FormRow>
                            <InputContainer
                              label={
                                <LanguageProvider id="devices.drones.serial" />
                              }
                            >
                              <InputText
                                value={item.serialNumber}
                                disabled
                              ></InputText>
                            </InputContainer>

                            <InputContainer
                              label={
                                <LanguageProvider id="datasource.column.nf" />
                              }
                            >
                              <InputText value={item.nf} disabled></InputText>
                            </InputContainer>

                            <InputContainer
                              label={<LanguageProvider id="gen.sku" />}
                            >
                              <InputText value={item.sku} disabled></InputText>
                            </InputContainer>
                          </FormRow>
                          <FormRow>
                            <InputContainer
                              label={<LanguageProvider id="gen.log" />}
                            >
                              <InputTextarea
                                disabled={true}
                                value={item.log}
                                rows={10}
                              ></InputTextarea>
                            </InputContainer>
                          </FormRow>
                        </Form>
                      </AccordionTab>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Button appearance="secondary" onClick={() => telnetApi("login")}>
            Validar login WMS
          </Button>
          <Button
            appearance="secondary"
            onClick={() => {
              setNf("");
              setSerialNumber("");
            }}
          >
            <LanguageProvider id="dcp.platform.warehouse.clean.data" />
          </Button>
          <Button onClick={() => loadItems()}>
            <LanguageProvider id="dashboard.update" />
          </Button>
        </div>
      </div>
    </div>
  );
}
