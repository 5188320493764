import "../scss/inventory-mobile.scss";
import { Button } from "primereact/button";
import { Form } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { FilterMatchMode } from "primereact/api";
import { useToastContext } from "~/context/ToastContext";
import { Column } from "primereact/column";
import settings from "~/services/settings.json";
import Icon from "../icons";
import ModalConfirmation from "../modal-confirmation";
import LanguageProviderWithoutContext from "../language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

export function InventoryMobile() {
  // Data
  const { currentLanguage } = useLanguageContext();
  const [mobile, setMobile] = useState([]);
  const [users, setUsers] = useState([]);
  const [licenses, setLicenses] = useState({});
  const [selectedEquipment, setSelectedEquipment] = useState({});
  // Loading indicators
  const [loadingMobile, setLoadingMobile] = useState(false);
  const [deleting, setDeleting] = useState(false);
  // Dialog controll
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const dcpAxiosService = useDcpAxiosService();
  // Filters
  const [droneFilter, setDroneFilter] = useState({
    first: 0,
    rows: 7,
    page: 0,
    sortField: null,
    sortOrder: null, // 1 or -1
    filters: {
      name: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      firmwareVersion: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      lastFlight: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      serial: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
    },
  });
  const [mobileFilter, setMobileFilter] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null, // 1 or -1
    filters: {
      name: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      firmwareVersion: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      lastFlight: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      serial: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
    },
  });
  const [mobileTotalRecords, setMobileTotalRecords] = useState(0);
  const { showToast } = useToastContext();

  async function loadMobile(payload) {
    try {
      setLoadingMobile(true);
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Equipment + "/list/mobile",
        payload,
        "Inventory"
      );

      if (status === 200) {
        setMobile(data.data.equipments);
        setMobileTotalRecords(data.data.totalRecords);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMobile(false);
    }
  }

  async function loadUsers() {
    try {
      const { data, status } = await dcpAxiosService.get(
        "/user/get-users",
        "Auth"
      );
      if (status === 200) {
        setUsers(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadLicenses() {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Admin.Config,
        "Auth"
      );
      if (status === 200) {
        setLicenses(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteDevice() {
    try {
      setDeleting(true);
      const { data, status } = await dcpAxiosService.delete(
        settings.Urls.Rest.Equipment,
        "Inventory",
        {
          params: {
            equipmentId: selectedEquipment.id,
          },
        }
      );
      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "device.delete.confirm",
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
      setDeleteModalVisible(false);
      loadMobile(formatFilterRequest(mobileFilter));
    }
  }

  // Format the filter object to be accepted by the endpoint
  function formatFilterRequest(filter) {
    try {
      let payload = { ...filter };
      payload.sortOrder = payload.sortOrder ?? 0;

      const filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const filter = payload.filters[columnName];

          if (filter.constraints[0].value)
            filters.push({
              columnName,
              value: filter.constraints[0].value,
              matchMode: filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = filters;
      return payload;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadMobile(formatFilterRequest(mobileFilter));
  }, [mobileFilter]);

  const onPage = (event, device) => {
    if (device == "mobile") setMobileFilter(event);
  };

  const onSort = (event, device) => {
    if (device == "mobile") setMobileFilter(event);
  };

  const onFilter = (event, device) => {
    event["first"] = 0;

    // hack to make the clear button work
    const _event = event;
    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    if (device == "drone") setDroneFilter(_event);
    else if (device == "mobile") setMobileFilter(_event);
  };

  function isDeviceOnline(rowData) {
    const onlineTimeLimit =
      Date.now() - settings.Licenses.OnlineDeviceCheckTime * 60000;
    const lastOnline = new Date(rowData.lastActivity);

    const online = lastOnline.getTime() > onlineTimeLimit;
    return online;
  }

  function statusColumnTemplate(rowData) {
    const online = isDeviceOnline(rowData);

    return (
      <div className={`pill ${online ? "online" : "offline"}`}>
        <span>
          {<LanguageProvider id={online ? "gen.online" : "gen.offline"} />}
        </span>
      </div>
    );
  }

  function actionsColumnTemplate(rowData) {
    const online = isDeviceOnline(rowData);

    return (
      <div className="table-actions-column">
        <Button
          disabled={online}
          className="p-button-plain p-button-text action-icon"
          onClick={(e) => {
            e.preventDefault();
            setSelectedEquipment(rowData);
            setDeleteModalVisible(true);
          }}
        >
          <Icon icon="trash-02" color="#667085" size={20} />
        </Button>
      </div>
    );
  }

  useEffect(() => {
    async function load() {
      await loadUsers();
      await loadMobile(formatFilterRequest(mobileFilter));
      await loadLicenses();
    }
    load();
  }, []);
  return (
    <div className="subgroup-container">
      <h3>
        <LanguageProvider id="devices.mobile" />
      </h3>
      <Form className="settings">
        <div className="mobile-list-panel">
          <div className="data-table-container">
            <DataTable
              className="dcp-table"
              loading={loadingMobile}
              value={mobile}
              paginator
              rows={10}
              scrollable
              scrollHeight="flex"
              lazy
              first={mobileFilter.first}
              onPage={(e) => onPage(e, "mobile")}
              onSort={(e) => onSort(e, "mobile")}
              onFilter={(e) => onFilter(e, "mobile")}
              sortField={mobileFilter.sortField}
              sortOrder={mobileFilter.sortOrder}
              totalRecords={mobileTotalRecords}
              filters={mobileFilter.filters}
            >
              <Column
                sortable
                filter
                field="name"
                header={<LanguageProvider id="product.drone.device" />}
              />
              <Column
                sortable
                filter
                field="user"
                body={(rowData) => {
                  const user = users.find((u) => u.userId === rowData.userId);
                  if (user) return <span>{user.name}</span>;
                  else return "";
                }}
                header={<LanguageProvider id="devices.mobile.user" />}
              />
              <Column
                sortable
                filter
                body={statusColumnTemplate}
                header={<LanguageProvider id="gen.status" />}
              />
              <Column
                sortable
                filter
                field="lastActivity"
                body={(rowData) => {
                  const date = new Date(rowData.lastActivity);
                  return date.toLocaleString();
                }}
                header={<LanguageProvider id="devices.mobile.last.access" />}
              />
              <Column body={actionsColumnTemplate} style={{ width: "40px" }} />
            </DataTable>
          </div>
        </div>
      </Form>
      <ModalConfirmation
        isOpen={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        isDelete={true}
        onConfirm={deleteDevice}
        loading={deleting}
        modalTitle={<LanguageProvider id="device.delete" />}
        bodyMessage={<LanguageProvider id="device.delete.msg" />}
      />
    </div>
  );
}
