import "./scss/settings-gpt-ai.scss";
import React, { useEffect, useState } from "react";
import LanguageProvider from "../../../shared/components/language-provider";
import settings from "~/services/settings.json";
import { Message } from "primereact/message";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { useHeaderHeight } from "~/hooks/useHeaderHeight";

export function GptAI() {
  const headerHeight = useHeaderHeight();
  const dcpAxiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  const [warningMessageVisibility, setWarningMessageVisibility] =
    useState(false);
  const [gptAITokenData, setGptAIToken] = useState(null);
  const [usedGPTAiTokenPercentValue, setUsedGPTAiTokenPercentValue] =
    useState(0);

  useEffect(() => {
    const getGptAIData = async () => {
      if (applicationClientId) {
        try {
          const { data, status } = await dcpAxiosService.get(
            `${settings.Urls.Rest.ChatGpt}/get-gpt-ai-use`,
            "AI",
            {
              params: { applicationClientId: applicationClientId },
            }
          );

          if (status === 200) {
            setGptAIToken(data.data);
            const usedAITokenPercent = (
              (data.data.tokensUsed / data.data.tokensHired) *
              100
            ).toFixed(0);
            setUsedGPTAiTokenPercentValue(usedAITokenPercent);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    getGptAIData();
  }, [applicationClientId, dcpAxiosService]);

  useEffect(() => {
    if (usedGPTAiTokenPercentValue >= 90) {
      setWarningMessageVisibility(true);
    }
  }, [usedGPTAiTokenPercentValue]);

  return (
    <div className="page-body-gpt-ai">
      <div
        className="settings-gpt-ai"
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
      >
        <div className="container">
          <div className="header">
            <h3>
              <LanguageProvider id="dcp.auth.side.menu.settings.gpt-ai" />
            </h3>
          </div>
          <div className="settings-content">
            <div className="settings-container">
              <div className="container-area">
                <div className="group-container">
                  <p className="gpt-ai-setting-description">
                    <LanguageProvider id="inventory-storage-fields.description" />
                  </p>
                  <div className="subgroup-gpt-ai-container">
                    {usedGPTAiTokenPercentValue >= 90 &&
                      warningMessageVisibility && (
                        <div className="storage-warning">
                          <Message
                            severity="warn"
                            content={
                              <div className="message-content">
                                <i className="pi pi-exclamation-triangle icon" />
                                <span className="message-text">
                                  <strong>
                                    <LanguageProvider
                                      id={
                                        "setting.platform.gpt.ai.tokens.used.warning"
                                      }
                                    />
                                  </strong>{" "}
                                  <LanguageProvider
                                    id={
                                      "setting.platform.gpt-ai.tokens.used.warning.description"
                                    }
                                  />
                                </span>
                                <button
                                  className="close-button"
                                  onClick={() =>
                                    setWarningMessageVisibility(false)
                                  }
                                >
                                  <i className="pi pi-times" />
                                </button>
                              </div>
                            }
                          />
                        </div>
                      )}
                    <span className="storage-info-title">
                      <LanguageProvider
                        id={"setting.platform.gpt.ai.use.title"}
                      />
                    </span>
                    <div className="storage-data-wrapper">
                      <div className="info">
                        <span className="info-used">
                          {gptAITokenData?.tokensUsed?.toFixed(2)} K Tokens
                        </span>
                        <span className="info-hired">
                          <LanguageProvider id={"gen.message.of"} />{" "}
                          {gptAITokenData?.tokensHired?.toFixed(2)} K Tokens
                        </span>
                      </div>
                      <div className="storage-line-wrapper">
                        <div className="progression-storage-bar">
                          <div
                            className="progression-storage-bar-filled"
                            style={{
                              width: `${
                                usedGPTAiTokenPercentValue > 100
                                  ? "100"
                                  : usedGPTAiTokenPercentValue.toString()
                              }%`,
                              backgroundColor:
                                usedGPTAiTokenPercentValue >= 90
                                  ? "red"
                                  : "blue",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span className="storage-hired-info">
                        {usedGPTAiTokenPercentValue > 100
                          ? 100
                          : usedGPTAiTokenPercentValue}
                        %{" "}
                        <LanguageProvider
                          id={"setting.platform.storage.used.message"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
