import { ColorPickerConfig } from "./ColorPicker";
import { DateConfig } from "./Date";
import { FontSizeConfig } from "./FontSize";
import { TextConfig } from "./Text";
import { TextAreaConfig } from "./TextArea";
import { TonThresholdConfig } from "./TonThreshold";
import { PercentageThresholdConfig } from "./PercentageThreshold";
import DecimalNumberConfig from "./DecimalNumber";
import { WMSCounterSourceConfig } from "./WMSCounterSourceConfig ";
import { InventorySelection as InventorySelectionConfig } from "./InventorySelection";
import { SequenceCounterSourceConfig } from "./SequenceCounterSourceConfig";
import { WarehouseCounterSourceConfig } from "./WarehouseCounterSourceConfig";
import { WarehouseFinanceCounterSourceConfig } from "./WarehouseFinanceCounterSourceConfig";
import { AmcGenericCounterConfig } from "./AmcGenericCounterSourceConfig";
import { InventoryLocationStatus } from "./InventoryLocationStatus";
import { HourGoalConfig } from "./HourGoal";
import { LastDaysDropwdown } from "./LastDaysDropdown";

const ConfigField = {
  Text: TextConfig,
  Date: DateConfig,
  FontSize: FontSizeConfig,
  ColorPicker: ColorPickerConfig,
  TextArea: TextAreaConfig,
  TonThreshold: TonThresholdConfig,
  PercentageThreshold: PercentageThresholdConfig,
  DecimalNumber: DecimalNumberConfig,
  WMSCounterSource: WMSCounterSourceConfig,
  InventorySelection: InventorySelectionConfig,
  SequenceCounterSource: SequenceCounterSourceConfig,
  WarehouseCounterSource: WarehouseCounterSourceConfig,
  WarehouseFinanceCounterSource: WarehouseFinanceCounterSourceConfig,
  AmcGenericCounterSource: AmcGenericCounterConfig,
  InventoryLocationStatus: InventoryLocationStatus,
  HourGoal: HourGoalConfig,
  LastDaysDropwdown: LastDaysDropwdown,
};

export default ConfigField;
