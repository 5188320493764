import "./scss/modal-settings-dashboard-timers.scss";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";
import { InputNumber } from "primereact/inputnumber";
import { Form, FormRow, InputContainer } from "./dcp-form";
import { useFormik } from "formik";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import { useEffect, useState } from "react";

const ModalSettingsDashboardTimers = ({ isOpen, dashboardId, onCancel }) => {
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [loadingTimers, setLoadingTimers] = useState(false);

  const settingDashboardTimersFormik = useFormik({
    initialValues: {
      autorefreshValueInSeconds: 0,
    },
    validate: (data) => {
      let errors = {};
      if (
        data.autorefreshValueInSeconds === 0 ||
        data.autorefreshValueInSeconds === null
      ) {
        errors.autorefreshValueInSeconds = (
          <LanguageProvider
            id={"dashboards.auto.refresh.time.validation.error"}
          />
        );
      }
      return errors;
    },
    onSubmit: async (settingTimerData) => {
      try {
        const DashboardCreateTimerModel = {
          dashboardId: dashboardId,
          dashboardsAutoplayTimeInSeconds:
            settingTimerData.autoplayValueInSeconds,
          chartsAutorefreshTimeInSeconds:
            settingTimerData.autorefreshValueInSeconds,
        };

        // CRIAR NOVO E ALTERAR ENDPOINT
        const { data, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.Dashboard}/save-dashboard-timers`,
          DashboardCreateTimerModel,
          "Platform"
        );

        if (status) {
          showToast({
            severity: "success",
            summary: "Success",
            message: (
              <LanguageProvider
                id={"dashboard.setting.autoplay.in.seconds.success.message"}
              />
            ),
          });
          settingDashboardTimersFormik.setValues({
            autorefreshValueInSeconds:
              settingTimerData.autorefreshValueInSeconds,
          });
        }
      } catch (error) {
        console.error(error);
        settingDashboardTimersFormik.resetForm();
      } finally {
        onCancel();
      }
    },
  });

  const IsSettingDashboardFormValid = (name) => {
    return !!(
      settingDashboardTimersFormik.touched[name] &&
      settingDashboardTimersFormik.errors[name]
    );
  };
  const GetSettingTimerFormErrorMessage = ({ name }) => {
    return IsSettingDashboardFormValid(name) ? (
      <small className="p-error">
        {settingDashboardTimersFormik.errors[name]}
      </small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const CloseModal = () => {
    onCancel();
    settingDashboardTimersFormik.resetForm();
  };

  useEffect(() => {
    if (dashboardId) {
      const fetchSavedDashboardTimers = async () => {
        setLoadingTimers(false);
        try {
          const { data, status } = await dcpAxiosService.get(
            `${settings.Urls.Rest.Dashboard}/get-dashboard-timers`,
            "Platform",
            {
              params: {
                dashboardId: dashboardId,
              },
            }
          );

          console.log(data);

          if (status) {
            settingDashboardTimersFormik.setValues({
              autorefreshValueInSeconds:
                data.data.chartsAutorefreshTimeInSeconds,
            });
          } else {
            console.error("Error while fetching saved dashboard timers");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingTimers(false);
        }
      };
      fetchSavedDashboardTimers();
    }
  }, [isOpen]);

  return (
    <ConfirmDialog
      className="setting-dashboard-timers-dialog"
      position="center"
      visible={isOpen}
      onHide={CloseModal}
      header={() => {
        return (
          <div className="modal-setting-timer-container">
            <div className="modal-title-wrapper">
              <p>
                <LanguageProvider id={"gen.change.timers.button"} />
              </p>
            </div>

            <div className="setting-timer-container">
              <Form
                className="form-setting-timer"
                onSubmit={settingDashboardTimersFormik.handleSubmit}
              >
                <FormRow>
                  <InputContainer
                    label={
                      <LanguageProvider
                        id={"dashboard.setting.chart.auto.refresh.in.seconds"}
                      />
                    }
                  >
                    <InputNumber
                      className="input-auto-refresh"
                      id="autorefreshValue"
                      name="autorefreshValue"
                      inputId="minmax-buttons"
                      value={
                        loadingTimers
                          ? "Carregando"
                          : settingDashboardTimersFormik.values
                              .autorefreshValueInSeconds
                      }
                      onValueChange={(e) =>
                        settingDashboardTimersFormik.setFieldValue(
                          "autorefreshValueInSeconds",
                          e.value
                        )
                      }
                      mode="decimal"
                      showButtons
                      min={0}
                    />
                    <GetSettingTimerFormErrorMessage name="autorefreshValueInSeconds" />
                  </InputContainer>
                </FormRow>
              </Form>
            </div>
          </div>
        );
      }}
      footer={() => {
        return (
          <div className="card flex justify-content-center">
            <Button
              label={<LanguageProvider id={"gen.cancel.button"} />}
              appearance="subtle"
              className="p-button p-button-text p-button-plain"
              onClick={CloseModal}
            />
            <Button
              label={<LanguageProvider id={"gen.save.button"} />}
              type="submit"
              appearance="danger"
              className="p-button p-primary"
              onClick={settingDashboardTimersFormik.handleSubmit}
              autoFocus
            />
          </div>
        );
      }}
    />
  );
};

export default ModalSettingsDashboardTimers;
