import "./scss/inventory-create.scss";

import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { useEffect, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { ReactComponent as DroneSVG } from "~/theme/custom-icons/drone-color-variation.svg";
import LanguageProvider from "~/shared/components/language-provider";
import { ReactComponent as ManualReadSVG } from "~/theme/custom-icons/manual-read.svg";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useToastContext } from "~/context/ToastContext";
import { useUsersContext } from "~/context/UsersContext";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export function InventoryCreate({ isOpen, onHide, onSave }) {
  const loggedInUserName = propertyFrommStorage("authentication", "userName");
  const users = useUsersContext();
  const { currentLanguage } = useLanguageContext();
  const axiosService = useDcpAxiosService();
  // Data
  const [drones, setDrones] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [selectedDrones, setSelectedDrones] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [skuOptionList, setSkuOptionList] = useState([]);
  const [locationOptionList, setLocationOptionList] = useState([]);
  // loading indicators
  const [loadingDrones, setLoadingDrones] = useState(false);
  const [skuOptionLoading, setSkuOptionLoading] = useState(false);
  const [locationOptionLoading, setLocationOptionLoading] = useState(false);
  // Other
  const { showToast } = useToastContext();
  const dcpAxiosService = useDcpAxiosService();

  const generateCode = () => {
    const timeCode = new Date().toLocaleString().replace(/\D/g, "");
    return timeCode;
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      code: generateCode(),
      estimatedLocations: 0,
      name: "",
      description: "",
      programmedTo: new Date(),
      drone: true,
      manual: false,
      selectedDrones: [],
      selectedUsers: [],
      selectedAreas: [],
      readType: 1,
      restrictionType: 3,
      codes: [],
    },
    onSubmit: saveInventory,
    validate: validateFields,
  });

  async function saveInventory(values) {
    try {
      const { status } = await axiosService.post(
        settings.Urls.Rest.Inventory + "/create",
        values,
        "Inventory"
      );

      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProviderWithoutContext({
            id: "inventory.save.success",
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      resetForm();
      onHide();
      onSave();
    }
  }

  function validateFields(values) {
    try {
      const errors = {};

      if (values.name.length < 1)
        errors.name = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });

      if (values.selectedAreas.length < 1) {
        errors.areas = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });
      }

      if (values.estimatedLocations.length < 1)
        errors.estimatedLocations = LanguageProviderWithoutContext({
          id: "gen.message.field.required",
          currentLanguage,
        });

      if (!values.manual && !values.drone)
        errors.type = LanguageProviderWithoutContext({
          id: "inventory.type.error",
          currentLanguage,
        });

      return errors;
    } catch (error) {
      console.error(error);
    }
  }

  function resetForm(values) {
    setselectedUsers([]);
    setSelectedDrones([]);
    setSelectedLocations([]);
    setSelectedAreas([]);
    formik.resetForm({
      values: { ...formik.initialValues, code: generateCode() },
    });
  }

  const isFormFieldInvalid = (name) => {
    return !(formik.touched[name] && formik.errors[name]);
  };

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error"></small>
    );
  };

  const loadDrones = async () => {
    try {
      setLoadingDrones(true);
      const { data, status } = await axiosService.post(
        settings.Urls.Rest.Equipment + "/list/drone",
        {
          first: 0,
          rows: 999999999,
          page: 0,
          sortField: null,
          sortOrder: 1, // 1 or -1
          filters: [],
        },
        "Inventory"
      );

      if (status === 200) {
        setDrones(data.data.equipments);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDrones(false);
    }
  };

  const loadSkus = async () => {
    try {
      setSkuOptionLoading(true);
      const { data } = await axiosService.get(
        `${settings.Urls.Rest.Inventory}/list-sku-mobile`,
        "Inventory"
      );

      setSkuOptionList(data.data);
    } catch (error) {
      console.error(error);
    }

    setSkuOptionLoading(false);
  };

  const loadLocations = async () => {
    try {
      setLocationOptionLoading(true);

      const areaIds = selectedAreas.map((a) => a.id);
      var locations = [];

      for (const areaId of areaIds) {
        const { status, data } = await dcpAxiosService.get(
          `${settings.Urls.Rest.AreaMap}/list-area-locations`,
          "Platform",
          {
            params: {
              idArea: areaId,
            },
          }
        );

        if (status === 200 && data.data) {
          locations.push(...data.data);
        }
      }

      setLocationOptionList(locations);
    } catch (error) {
      console.error(error);
    }
    setLocationOptionLoading(false);
  };

  const loadAreas = async () => {
    try {
      const { data } = await axiosService.get(
        `${settings.Urls.Rest.AreaMap}/list-areas`,
        "Platform"
      );

      if (Array.isArray(data.data)) {
        setAreas(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAreaLocationsFilter = async (event) => {
    if (!selectedAreas.length > 0) return;
    const areaIds = selectedAreas.map((a) => a.id);
    try {
      const { status, data } = await dcpAxiosService.post(
        `${settings.Urls.Rest.Inventory}/filter-list-area-locations`,
        {
          areaIds: areaIds,
          query: event.filter,
        },
        "Inventory"
      );
      if (status === 200 && data.data) {
        setLocationOptionList(
          data.data.map((row) => ({
            name: row.name,
            value: row.id,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeSelectedLocationList = (e) => {
    setSelectedLocations(e.value);
    const locationSelectedName = e.value.map(
      (l) => locationOptionList.find((lo) => lo.id === l.id).name
    );
    formik.setFieldValue("codes", locationSelectedName);
    formik.setFieldValue(
      "estimatedLocations",
      e.value.length > 0 ? e.value.length : 0
    );
  };
  const changeSelectedUsers = (e) => {
    try {
      setselectedUsers(e.value);
      formik.setFieldValue(
        "selectedUsers",
        e.value.map((d) => d.userId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const selectSingleUser = (user) => {
    try {
      setselectedUsers(user);
      formik.setFieldValue(
        "selectedUsers",
        user.map((d) => d.userId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const selectLoggedInUserByDefault = () => {
    if (Array.isArray(users) && users?.length > 0) {
      try {
        const selectLoggedUserId = users.find(
          (u) => u.userName === loggedInUserName
        );
        setselectedUsers([selectLoggedUserId]);
        formik.setFieldValue("selectedUsers", [selectLoggedUserId.userId]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const changeSelectedDrones = (e) => {
    try {
      setSelectedDrones(e.value);
      formik.setFieldValue(
        "selectedDrones",
        e.value.map((d) => d.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const selectSingledDrone = (drone) => {
    try {
      setSelectedDrones(drone);
      formik.setFieldValue(
        "selectedDrones",
        drone.map((d) => d.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const changeSelectedAreas = (e) => {
    try {
      setSelectedAreas(e.value);
      formik.setFieldValue(
        "selectedAreas",
        e.value.map((a) => a.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const selectSingleArea = (area) => {
    try {
      setSelectedAreas(area);
      formik.setFieldValue(
        "selectedAreas",
        area.map((a) => a.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const cancelForm = () => {
    resetForm();
    onHide();
  };

  useEffect(() => {
    if (selectedAreas.length > 0) {
      loadLocations();
    }
  }, [selectedAreas]);

  useEffect(() => {
    loadDrones();
    loadAreas();
    // loadMoreSkus(0);
    loadSkus();
  }, []);

  useEffect(() => {
    selectLoggedInUserByDefault();
  }, [users]);

  return (
    <Dialog
      className={"create-inventory-dialog"}
      appendTo={"self"}
      visible={isOpen}
      onHide={cancelForm}
      closeOnEscape={false}
      draggable={false}
    >
      <div className="main-container">
        <div className="content">
          <div className="inventory-options">
            <h2 className="title">
              <LanguageProvider id="inventory.create.new" />
            </h2>
            <p className="description">
              <LanguageProvider id="inventory.create.description.1" />
            </p>
            <p className="description">
              <LanguageProvider id="inventory.create.description.2" />
            </p>
            <p className="description">
              <LanguageProvider id="inventory.create.description.3" />
            </p>
            <Form className="inputs">
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.code" />}>
                  <InputText value={formik.values.code} disabled={true} />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.name" />}>
                  <InputText
                    invalid={isFormFieldInvalid("name")}
                    value={formik.values.name}
                    placeholder={LanguageProvider({
                      id: "inventory.name.placeholder",
                    })}
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target.value)
                    }
                  />
                  {getFormErrorMessage("name")}
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer label={<LanguageProvider id="areas" />}>
                  <MultiSelect
                    className="areas-selection"
                    display="chip"
                    maxSelectedLabels={3}
                    options={areas}
                    value={
                      selectedAreas.length === 0 && areas.length === 1
                        ? selectSingleArea([areas[0]])
                        : selectedAreas
                    }
                    optionLabel="name"
                    onChange={changeSelectedAreas}
                  />
                  {getFormErrorMessage("selectedAreas")}
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="inventory.predicted.locations" />
                  }
                >
                  <InputText
                    value={formik.values.estimatedLocations}
                    placeholder={LanguageProvider({
                      id: "inventory.predicted.locations.placeholder",
                    })}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || !isNaN(parseInt(value))) {
                        formik.setFieldValue("estimatedLocations", value);
                      }
                    }}
                  />
                  {getFormErrorMessage("estimatedLocations")}
                </InputContainer>
                <InputContainer
                  label={<LanguageProvider id="inventory.programmed.to" />}
                >
                  <Calendar
                    value={formik.values.programmedTo}
                    onChange={(e) =>
                      formik.setFieldValue("programmedTo", e.value)
                    }
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.description" />}
                >
                  <InputTextarea
                    rows={5}
                    autoResize
                    value={formik.values.description}
                    placeholder={LanguageProvider({
                      id: "inventory.description.placeholder",
                    })}
                    onChange={(e) =>
                      formik.setFieldValue("description", e.target.value)
                    }
                  />
                </InputContainer>
              </FormRow>
            </Form>
          </div>
          <div className="equipment-type">
            <div className="read-type-wrapper">
              <p className="label type">
                <LanguageProvider id="inventory.type" />
              </p>
              <div className="read-type">
                <div className="radio">
                  <RadioButton
                    value={1}
                    checked={formik.values.readType === 1}
                    onChange={(e) => formik.setFieldValue("readType", e.value)}
                  />
                  <span className="label">
                    <LanguageProvider id="inventory.read.type.sku" />
                  </span>
                </div>
                {/* <div className="radio">
                  <RadioButton
                    value={2}
                    checked={formik.values.readType === 2}
                    onChange={(e) => formik.setFieldValue("readType", e.value)}
                  />
                  <span className="label">
                    <LanguageProvider id="inventory.read.type.serial" />
                  </span>
                </div> */}
              </div>
            </div>

            <div className="type-container">
              <p className="label type">
                <LanguageProvider id="inventory.type" />
              </p>
              <div
                className={`type-card ${formik.values.drone ? "active" : null}`}
              >
                <div className="icon">
                  <DroneSVG />
                </div>
                <div className="card-content">
                  <h3 className="title">
                    <LanguageProvider id="inventory.type.drone" />
                  </h3>
                  <p className="description">
                    <LanguageProvider id="inventory.create.drone.description" />
                  </p>
                </div>
                <div className="check-container">
                  <Checkbox
                    invalid={() => isFormFieldInvalid("type")}
                    checked={formik.values.drone}
                    onChange={(e) => formik.setFieldValue("drone", e.checked)}
                  ></Checkbox>
                </div>
              </div>
              {getFormErrorMessage("type")}
            </div>
            <div className="selection">
              <p className="label">
                <LanguageProvider id="devices.drones" />
              </p>
              <MultiSelect
                className="drones-selection"
                disabled={!formik.values.drone}
                display="chip"
                options={drones}
                value={
                  selectedDrones.length === 0 && drones.length === 1
                    ? selectSingledDrone([drones[0]])
                    : selectedDrones
                }
                optionLabel="name"
                loading={loadingDrones}
                onChange={changeSelectedDrones}
                maxSelectedLabels={2}
              />
            </div>
            <div className="type-container">
              <div
                className={`type-card ${
                  formik.values.manual ? "active" : null
                }`}
              >
                <div className="icon">
                  <ManualReadSVG />
                </div>
                <div className="card-content">
                  <h3 className="title">
                    <LanguageProvider id="inventory.type.manual" />
                  </h3>
                  <p className="description">
                    <LanguageProvider id="inventory.create.drone.description" />
                  </p>
                </div>
                <div className="check-container">
                  <Checkbox
                    invalid={() => isFormFieldInvalid("type")}
                    checked={formik.values.manual}
                    onChange={(e) => formik.setFieldValue("manual", e.checked)}
                  ></Checkbox>
                </div>
              </div>
              {getFormErrorMessage("type")}
            </div>
            <div className="selection">
              <p className="label">
                <LanguageProvider id="gen.responsibles" />
              </p>
              <MultiSelect
                className="users-selection"
                disabled={!formik.values.manual}
                display="chip"
                options={users}
                value={
                  selectedUsers?.length === 0 &&
                  users?.length === 1 &&
                  formik.values.manual
                    ? selectSingleUser([users[0]])
                    : selectedUsers
                }
                optionLabel="name"
                onChange={changeSelectedUsers}
                maxSelectedLabels={3}
              />
            </div>
            <div className="inventory-type">
              <div className="read-type-wrapper">
                <p className="label type">
                  <LanguageProvider id="inventory.block.type" />
                </p>
                <div className="read-type">
                  <div className="radio">
                    <RadioButton
                      value={2}
                      checked={formik.values.restrictionType === 2}
                      onChange={(e) => {
                        formik.setFieldValue("restrictionType", e.value);
                        formik.setFieldValue("codes", []);
                      }}
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.sku" />
                    </span>
                  </div>
                  <div className="radio">
                    <RadioButton
                      value={1}
                      checked={formik.values.restrictionType === 1}
                      onChange={(e) => {
                        formik.setFieldValue("restrictionType", e.value);
                        formik.setFieldValue("codes", []);
                      }}
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.location" />
                    </span>
                  </div>
                  <div className="radio">
                    <RadioButton
                      value={3}
                      checked={formik.values.restrictionType === 3}
                      onChange={(e) =>
                        formik.setFieldValue("restrictionType", e.value)
                      }
                    />
                    <span className="label">
                      <LanguageProvider id="inventory.read.type.no.block" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {formik.values.restrictionType === 2 && (
              <div className="selection">
                <MultiSelect
                  className="sku-selection"
                  display="chip"
                  filter
                  virtualScrollerOptions={{
                    itemSize: 38,
                  }}
                  options={skuOptionList}
                  value={formik.values.codes}
                  optionLabel="pattern"
                  optionValue="code"
                  onChange={(e) => formik.setFieldValue("codes", e.value)}
                  loading={skuOptionLoading}
                  disabled={formik.values.restrictionType === 3 ? true : false}
                />
              </div>
            )}
            {formik.values.restrictionType === 1 && (
              <div className="selection">
                <MultiSelect
                  className="location-selection"
                  display="chip"
                  filter
                  onFilter={(e) => handleAreaLocationsFilter(e)}
                  options={locationOptionList}
                  value={selectedLocations}
                  optionLabel="name"
                  onChange={changeSelectedLocationList}
                  // onChange={(e) => formik.setFieldValue("codes", e.value)}
                  loading={locationOptionLoading}
                  disabled={
                    formik.values.restrictionType === 3 ||
                    selectedAreas.length <= 0
                      ? true
                      : false
                  }
                  virtualScrollerOptions={{
                    itemSize: 38,
                  }}
                />
              </div>
            )}
            {formik.values.restrictionType === 3 && (
              <div className="selection">
                <MultiSelect
                  className="location-selection"
                  display="chip"
                  filter
                  optionLabel="name"
                  disabled={formik.values.restrictionType === 3 ? true : false}
                />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Button appearance="secondary" onClick={cancelForm}>
            <LanguageProvider id="gen.cancel.button" />
          </Button>
          <Button
            disabled={Object.keys(formik.errors).length > 0}
            onClick={() => formik.handleSubmit()}
          >
            <LanguageProvider id="gen.create.button" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
