import "./scss/side-menu.scss";
import {
  DcpNavMenuItemTemplate,
  DcpNewItemPopupMenuTemplate,
} from "./product-navigation/components/nav-menu-item-template";
import { useContext, useEffect, useRef, useState } from "react";

import ptBR from "~/language/pt-br.json";
import enUS from "~/language/en-us.json";
import esES from "~/language/es-es.json";
import frFR from "~/language/fr-fr.json";
import LoadingIndicator from "../components/dcp-loading-indicator";
import { PanelMenu } from "primereact/panelmenu";
import { ProductContext } from "../main-layout";
import { Toast } from "primereact/toast";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useLanguageContext } from "~/context/LanguageContext";
import { getFromStorage } from "~/services/storage/storage-access";

const SideMenu = () => {
  const { currentLanguage } = useLanguageContext();
  const { product } = useContext(ProductContext);
  const [loadingMenus, setLoadingMenus] = useState(true);
  const toast = useRef(null);
  const dcpAxiosService = useDcpAxiosService();
  const [datalakes, setDatalakes] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  const TranslatedLabel = ({ id }) => {
    const { currentLanguage } = useLanguageContext();
    const getTranslation = (id) => {
      switch (currentLanguage) {
        case "pt-br":
          return ptBR[id] || id;
        case "es-es":
          return esES[id] || id;
        case "en-us":
          return enUS[id] || id;
        case "fr-fr":
          return frFR[id] || id;
        default:
          return enUS[id] || id;
      }
    };
    return <>{getTranslation(id)}</>;
  };

  const [menuItems, setMenuItems] = useState([
    {
      key: "product-menu",
      className: "product-menu-panel-menu",
      label: "",
      items: [],
      // template: DcpNewItemPopupMenuTemplate,
      icon: "chevron-right",
      iconPosition: "start",
    },
    {
      key: "dashboards",
      className: "dashboards-panel-menu",
      label: <TranslatedLabel id={"gen.dashboards.upperCase"} />,
      items: [],
      template: DcpNavMenuItemTemplate,
      listType: "dashboards",
      icon: "chevron-right",
      isActive: true,
      iconPosition: "start",
    },
    {
      key: "datalakes",
      className: "databases-panel-menu",
      label: <TranslatedLabel id={"gen.datalakes.upperCase"} />,
      items: [],
      template: DcpNavMenuItemTemplate,
      listType: "datalakes",
      icon: "chevron-right",
      iconPosition: "start",
    },
  ]);

  async function loadDashboards() {
    try {
      const dashboardResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Dashboard + "/list",
        "Platform"
      );
      if (dashboardResponse.status === 200 && dashboardResponse.data.status) {
        setDashboards(dashboardResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadDatalakes() {
    try {
      const datalakeResponse = await dcpAxiosService.get(
        `${settings.Urls.Rest.Datalake}/list`,
        "Platform",
        {
          params: {
            applicationId: product && product.idApplicationClient,
          },
        }
      );
      if (datalakeResponse.status === 200 && datalakeResponse.data.status) {
        setDatalakes(datalakeResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const PanelMenuLoadingState = () => {
    //TODO need to change this to render a skeleton of the menu
    return <LoadingIndicator />;
  };

  function setupNavigation(product, datalakes, dashboards) {
    try {
      let datalakeMenus = [];
      if (datalakes && Array.isArray(datalakes)) {
        datalakes.forEach((datalake, datalakeIndex) => {
          if (
            datalake.name !== "" &&
            datalake.name !== null &&
            datalake.name !== undefined
          ) {
            let url =
              datalake.type === settings.DatalakeType.Database
                ? `database/list/${datalake.idDataSource}`
                : `reports/${datalake.idDataSource}`;
            datalakeMenus.push({
              key: `datalakes-${datalakeIndex}`,
              label: datalake.name,
              url: url,
              template: DcpNavMenuItemTemplate,
              type: datalake.type,
            });
          }
        });
      }
      let dashboardMenus = [];
      if (dashboards && Array.isArray(dashboards)) {
        dashboards.forEach((dashboard, dashboardIndex) => {
          if (
            dashboard.name !== "" ||
            dashboard.name !== null ||
            dashboard.name !== undefined
          ) {
            dashboardMenus.push({
              key: `dashboards-${dashboardIndex}`,
              label: dashboard.name,
              url: `dashboard-graphs/${dashboard.id}`,
              template: DcpNavMenuItemTemplate,
              type: "dashboard",
            });
          }
        });
      }
      let productMenu;

      if (product) {
        switch (product.code) {
          case "drone-inventory":
            productMenu = {
              key: "drone-inventory",
              className: "drone-inventory-panel-menu",
              label: <TranslatedLabel id={"gen.inventory.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "drone-inventory-dashboard",
                  url: "home/drone-inventory",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "inventory-registrations",
                  label: <TranslatedLabel id={"dcp.side.menu.registrations"} />,
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "inventory-skus",
                      label: <TranslatedLabel id={"dcp.side.menu.skus"} />,
                      url: "skuInventory",
                      iconPosition: "end",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "inventory-stock",
                      label: <TranslatedLabel id={"dcp.side.menu.stock"} />,
                      url: "inventoryStock",
                      iconPosition: "end",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "inventort-printer",
                      label: (
                        <TranslatedLabel id={"product.print.menu.printers"} />
                      ),
                      url: "printerInventory",
                      iconPosition: "end",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "inventory-identification-print",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.inventory.identification.print"}
                    />
                  ),
                  icon: "file-02",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "identification-interface",
                      url: "identification-interface",
                      label: (
                        <TranslatedLabel
                          id={
                            "dcp.side.menu.inventory.identification.print.interface"
                          }
                        />
                      ),
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "identification-print",
                      url: "identification-print",
                      label: (
                        <TranslatedLabel
                          id={
                            "dcp.side.menu.inventory.identification.print.print"
                          }
                        />
                      ),
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "inventory-picking-count",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.inventory.picking.count"}
                    />
                  ),
                  icon: "file-02",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "picking-count-interface",
                      url: "picking-count-interface",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.inventory.picking.count.interface"}
                        />
                      ),
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "picking-count-map",
                      url: "picking-count-map",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.inventory.picking.count.map"}
                        />
                      ),
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "drone-inventory-inventory",
                  url: "inventory",
                  label: <TranslatedLabel id={"dcp.side.menu.inventory"} />,
                  icon: "file-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "area-map-history",
                  url: "area-map-history",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.inventory.area.history"}
                    />
                  ),
                  icon: "calendar-check-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "inventory-storage",
                  url: "area-map",
                  label: <TranslatedLabel id={"dcp.side.menu.storage"} />,
                  icon: "warehouse-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "drone-inventory-floor-area",
                  url: "floor-area",
                  icon: "layout-grid-01",
                  label: <TranslatedLabel id={"dcp.side.menu.floor.area"} />,
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "drone-inventory-racks",
                  url: "layout",
                  icon: "rack-01",
                  label: <TranslatedLabel id={"dcp.side.menu.racks"} />,
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "drone-inventory-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  url: "reports",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "drone-inventory-settings",
                  url: "settings-inventory",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };
            break;
          case "wms":
          case "sequence":
            productMenu = {
              key: "sequence",
              className: "sequence-panel-menu",
              label: <TranslatedLabel id={"product.sequence.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "sequence-dashboard",
                  url: "home/sequence",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "sequence-registrations",
                  label: <TranslatedLabel id={"dcp.side.menu.registrations"} />,
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-sequence-part-number",
                      label: (
                        <TranslatedLabel
                          id={"product.sequence.menu.part.number"}
                        />
                      ),
                      url: "part-number",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-serial-block",
                      label: (
                        <TranslatedLabel
                          id={"product.sequence.menu.serial.block"}
                        />
                      ),
                      url: "serial-block",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-printers",
                      label: (
                        <TranslatedLabel
                          id={"product.sequence.menu.printers"}
                        />
                      ),
                      url: "printers",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "sequence-trips",
                  url: "trips",
                  label: (
                    <TranslatedLabel id={"dcp.side.menu.sequence.trips"} />
                  ),
                  icon: "recebimento",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "sequence-andon",
                  label: (
                    <TranslatedLabel id={"dcp.side.menu.sequence.andon"} />
                  ),
                  icon: "truck-01",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-sequence-andon-trip",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.sequence.andon.trip"}
                        />
                      ),
                      url: "andon/trip",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-andon-component",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.sequence.andon.component"}
                        />
                      ),
                      url: "andon/component",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "sequence-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-sequence-registrations-manage",
                      label: <TranslatedLabel id={"dcp.side.menu.manage"} />,
                      url: "reports",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "sequence-settings",
                  url: "settings-sequence",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };
            break;
          case "warehouse":
            productMenu = {
              key: "warehouse",
              className: "warehouse-panel-menu",
              label: <TranslatedLabel id={"product.warehouse.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "dashboard",
                  url: "home/warehouse",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "warehouse-registrations",
                  label: <TranslatedLabel id={"dcp.side.menu.registrations"} />,
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-warehouse-registrations-manage-sku",
                      label: (
                        <TranslatedLabel id={"product.warehouse.menu.skus"} />
                      ),
                      url: "sku-warehouse",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-warehouse-registrations-manage-family",
                      label: (
                        <TranslatedLabel id={"product.warehouse.menu.family"} />
                      ),
                      url: "family",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-registrations-manage-requesters",
                      label: (
                        <TranslatedLabel
                          id={"product.warehouse.menu.requesters"}
                        />
                      ),
                      url: "applicants",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-registrations-manage-addresses",
                      label: (
                        <TranslatedLabel
                          id={"product.warehouse.menu.addresses"}
                        />
                      ),
                      url: "addresses",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-sequence-registrations-manage-providers",
                      label: (
                        <TranslatedLabel
                          id={"product.warehouse.menu.providers"}
                        />
                      ),
                      url: "providers",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "warehouse-purchase-requests",
                  url: "purchase-requests",
                  label: (
                    <TranslatedLabel id={"dcp.side.menu.purchase.requests"} />
                  ),
                  icon: "shopping-cart-01",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "warehouse-movements",
                  label: (
                    <TranslatedLabel id={"dcp.side.menu.movements.sub.menu"} />
                  ),
                  icon: "dataflow-03",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "warehouse-movements-entrance",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.movements.entrance"}
                        />
                      ),
                      url: "entry",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-exit",
                      label: (
                        <TranslatedLabel id={"dcp.side.menu.movements.exit"} />
                      ),
                      url: "output",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-transfer-branches",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.movements.transfer.branches"}
                        />
                      ),
                      url: "transfer-branches",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-return",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.movements.return"}
                        />
                      ),
                      url: "return",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-loan",
                      label: (
                        <TranslatedLabel id={"dcp.side.menu.movements.loan"} />
                      ),
                      url: "loan",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-movement",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.movements.movement"}
                        />
                      ),
                      url: "movement",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "warehouse-movements-discard",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.movements.discard"}
                        />
                      ),
                      url: "discard",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "warehouse-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-warehouse-registrations-manage",
                      label: <TranslatedLabel id={"dcp.side.menu.manage"} />,
                      url: "reports",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "warehouse-settings",
                  url: "settings-warehouse",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };
            break;
          case "area-movement-control":
            productMenu = {
              key: "movement-control",
              className: "movement-control-panel-menu",
              label: (
                <TranslatedLabel id={"product.control.movement.upperCase"} />
              ),
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "movement-control-dashboard",
                  url: "home/area-movement-control",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "movement-control-registrations",
                  label: <TranslatedLabel id={"dcp.side.menu.registrations"} />,
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-movement-control-skus",
                      label: (
                        <TranslatedLabel id={"product.warehouse.menu.skus"} />
                      ),
                      url: "skus",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-movement-control-racks",
                      label: <TranslatedLabel id={"dcp.side.menu.racks"} />,
                      url: "layout",
                      template: DcpNavMenuItemTemplate,
                    },
                    {
                      key: "drone-movement-control-areas",
                      label: <TranslatedLabel id={"dcp.side.menu.areas"} />,
                      url: "area-map",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "movement-control-settings",
                  url: "requests",
                  label: <TranslatedLabel id={"product.area.requests"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "movement-control-occupation-map",
                  url: "amc-occupation-map",
                  label: <TranslatedLabel id={"product.area.map.occupation"} />,
                  icon: "map-01",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "movement-control-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "drone-movement-control-registrations-manage",
                      label: <TranslatedLabel id={"dcp.side.menu.manage"} />,
                      url: "reports",
                      icon: "",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "movement-control-settings",
                  url: "settings-area-control-movement",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };
            break;
          case "product-wd":
            productMenu = {
              key: "productWd",
              className: "product-wd-panel-menu",
              label: <TranslatedLabel id={"product.wd.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "product-wd-accounting-balances",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.product.wd.actions.accounting.balance"}
                    />
                  ),
                  url: "accounting-balances",
                  icon: "",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-wd-open-titles",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.product.wd.actions.open.titles"}
                    />
                  ),
                  url: "open-titles",
                  icon: "",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-wd-fixed-assets",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.product.wd.actions.fixed.assets"}
                    />
                  ),
                  url: "fixed-assets",
                  icon: "",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-wd-xml-files",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.product.wd.actions.xml.files"}
                    />
                  ),
                  url: "xml-files",
                  icon: "",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-dw-settings",
                  url: "settings-product-dw",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };

            break;
          case "print":
            productMenu = {
              key: "print",
              className: "print-panel-menu",
              label: <TranslatedLabel id={"product.print.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "print-dashboard",
                  url: "home/print",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "print-printers",
                  label: <TranslatedLabel id={"product.print.menu.printers"} />,
                  url: "print-printers",
                  icon: "printer",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "printed-data",
                  label: (
                    <TranslatedLabel id={"product.print.menu.printed.data"} />
                  ),
                  url: "printer-data",
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "printed-action",
                  label: (
                    <TranslatedLabel id={"product.print.menu.printed.action"} />
                  ),
                  url: "print-action",
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "template",
                  label: <TranslatedLabel id={"product.print.menu.template"} />,
                  url: "template",
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-print-settings",
                  url: "settings-product-print",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };

            break;
          case "documents-print":
            productMenu = {
              key: "print",
              className: "print-panel-menu",
              label: <TranslatedLabel id={"product.print.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "document-print-dashboard",
                  url: "home/documents-print",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "document-print-printers",
                  label: <TranslatedLabel id={"product.print.menu.printers"} />,
                  url: "document-print-printers",
                  icon: "printer",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "document-print-interface",
                  label: (
                    <TranslatedLabel id={"product.print.menu.printed.data"} />
                  ),
                  url: "document-print-interface",
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "document-print-action",
                  label: (
                    <TranslatedLabel id={"product.print.menu.printed.action"} />
                  ),
                  url: "document-print-action",
                  icon: "file-06",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "document-print-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  url: "reports",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "product-print-settings",
                  url: "settings-product-print",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };

            break;
          case "wms-core-ceva":
            productMenu = {
              key: "",
              className: "stage-panel-menu",
              label: <TranslatedLabel id={"product.wms-core-ceva.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "wms-core-ceva-dashboard",
                  url: "home/wms-core-ceva",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-skus",
                  url: "home/wms-core-ceva-skus",
                  label: <TranslatedLabel id={"dcp.side.menu.skus.wms.core"} />,
                  icon: "file-06",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-estacao-trabalho",
                  url: "home/wms-core-ceva-estacao-trabalho",
                  label: (
                    <TranslatedLabel id={"dcp.side.menu.estacao.wms.core"} />
                  ),
                  icon: "file-06",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-picking-cartao",
                  url: "home/wms-core-ceva-picking-cartao",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.picking.cartao.wms.core"}
                    />
                  ),
                  icon: "file-06",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-picking-multi",
                  url: "home/wms-core-ceva-picking-multi",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.picking.multi.wms.core"}
                    />
                  ),
                  icon: "file-06",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  url: "reports",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "wms-core-ceva-settings",
                  url: "home/wms-core-ceva-settings",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };

            break;
          case "stage-control":
            productMenu = {
              key: "stage-control",
              className: "stage-panel-menu",
              label: <TranslatedLabel id={"product.stage-control.upperCase"} />,
              expanded: true,
              template: DcpNewItemPopupMenuTemplate,
              items: [
                {
                  key: "stage-dashboard",
                  url: "home/stage-control",
                  label: <TranslatedLabel id={"dcp.side.menu.dashboard"} />,
                  icon: "monitor-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-orders",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.stage-control.orders"}
                    />
                  ),
                  icon: "file-02",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                  items: [
                    {
                      key: "stage-orders",
                      label: (
                        <TranslatedLabel
                          id={"dcp.side.menu.stage-control.orders"}
                        />
                      ),
                      url: "stage-orders",
                      template: DcpNavMenuItemTemplate,
                    },
                  ],
                },
                {
                  key: "stage-heat-map",
                  url: "picking-count-map",
                  label: (
                    <TranslatedLabel
                      id={"dcp.side.menu.stage-control.picking.count.map"}
                    />
                  ),
                  icon: "file-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-storage",
                  url: "area-map",
                  label: <TranslatedLabel id={"dcp.side.menu.storage"} />,
                  icon: "warehouse-02",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-floor-area",
                  url: "floor-area",
                  icon: "layout-grid-01",
                  label: <TranslatedLabel id={"dcp.side.menu.floor.area"} />,
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-racks",
                  url: "layout",
                  icon: "rack-01",
                  label: <TranslatedLabel id={"dcp.side.menu.racks"} />,
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-reports",
                  label: <TranslatedLabel id={"dcp.side.menu.reports"} />,
                  icon: "line-chart-up-01",
                  url: "reports",
                  iconPosition: "end",
                  template: DcpNavMenuItemTemplate,
                },
                {
                  key: "stage-settings",
                  url: "settings-print",
                  label: <TranslatedLabel id={"dcp.side.menu.settings"} />,
                  icon: "settings-04",
                  template: DcpNavMenuItemTemplate,
                },
              ],
            };

            break;
          default:
            break;
        }
      }
      return { dashboardMenus, datalakeMenus, productMenu };
    } catch (error) {
      console.error(error);
    }
    return;
  }

  useEffect(() => {
    if (product && dashboards && datalakes) {
      const navigationMenu = setupNavigation(product, datalakes, dashboards);

      let menuItemModel = menuItems;
      if (navigationMenu) {
        if (navigationMenu.productMenu) {
          const productMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "product-menu"
          );
          menuItemModel[productMenuIndex] = navigationMenu.productMenu;
        } else {
          menuItemModel = menuItemModel.filter((x) => x.key !== "product-menu");
        }

        if (navigationMenu.dashboardMenus) {
          const dashboardMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "dashboards"
          );
          menuItemModel[dashboardMenuIndex].items =
            navigationMenu.dashboardMenus;
          menuItemModel[dashboardMenuIndex].expanded = false;
        }

        if (navigationMenu.datalakeMenus) {
          const databasesMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "datalakes"
          );
          menuItemModel[databasesMenuIndex].items =
            navigationMenu.datalakeMenus;
        }
      }
      setLoadingMenus(false);
      setMenuItems(menuItemModel);
    }
  }, [product, datalakes, dashboards, menuItems, currentLanguage]);

  useEffect(() => {
    loadDatalakes();
    loadDashboards();
  }, [product]);

  return (
    <div className="side-menu">
      <div className="side-menu-container">
        <div className="navigation">
          <Toast ref={toast}></Toast>
          {loadingMenus ? (
            <PanelMenuLoadingState />
          ) : (
            <PanelMenu className="dcp-panel-menu" model={menuItems} multiple />
          )}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
