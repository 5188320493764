import { Field, Form, Formik } from "formik";
import "../scss/upload-data.scss";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Button } from "~/shared/components/dcp-button";
import uploadImg from "~/theme/media/assets/Upload.svg";
import LanguageProvider from "~/shared/components/language-provider";
import { CHART_COLORS } from "../../dashboard-graphs/components/grid-item/items/grid-item-helpers";
import settings from "~/services/settings.json";
import { saveDatabaseUploadedFile } from "~/services/api";
import { useToastContext } from "~/context/ToastContext";
import Icon from "~/shared/components/icons";
import { ThemeContext } from "~/app";

interface Column {
  name: string;
}

export const UploadDataDialog = ({
  visible,
  onHide,
  databaseColumns,
  onFinish,
  database,
  fileUploadConcluded,
}) => {
  const [
    dataAssociationToDatalakeVisible,
    setDataAssociationToDatalakeVisible,
  ] = useState<boolean>(false);
  const { currentTheme } = useContext(ThemeContext);
  const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
  const { showToast } = useToastContext();
  const [columns, setColumns] = useState<Column[]>([]);
  const [rowsData, setRowsData] = useState([] as any);
  const [fileName, setFileName] = useState<string>("");
  const [allChunksCompleted, setAllChunksCompleted] = useState<boolean>(false);
  const [uploadRegistersEnded, setUploadRegistersEnded] =
    useState<boolean>(false);
  const [loadingImportingRegisters, setLoadingImportingRegisters] =
    useState<boolean>(false);
  const [donePercentage, setDonePercentage] = useState<number>(0);
  const [doneRows, setDoneRows] = useState<number>(0);
  const fileInputRef = useRef(null);

  const abortController = useRef(new AbortController());

  // Formik settings
  const initialValues = {
    selectedColumns: Array(databaseColumns.length).fill(""),
  };
  const validate = (values) => {
    const errors = { selectedColumns: [] };
    values.selectedColumns.forEach((value, index) => {
      if (value === null || value === "" || value === undefined) {
        errors.selectedColumns[index] = (
          <LanguageProvider id={"gen.message.field.required"} />
        );
      }
    });
    if (errors.selectedColumns.length === 0) {
      return {};
    }
    return errors;
  };

  const hide = () => {
    if (onHide) {
      setDonePercentage(0);
      onHide();
    }
  };

  const handleCancel = () => {
    abortController.current.abort();
    setShowCancelButton(false);
    showToast({
      severity: "info",
      message: <LanguageProvider id={"database.upload.file.aborted"} />,
    });
    setDonePercentage(0);
    setUploadRegistersEnded(false);
    setLoadingImportingRegisters(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const isDate = (value) => {
    if (typeof value !== "number") return false;

    if (value % 1 !== 0) return true;

    const minDateValue = new Date(1950, 0, 1);
    const maxDateValue = new Date(9999, 11, 31);
    const excelEpoch = new Date(1900, 0, 1);

    const valueToDate = new Date(
      excelEpoch.getTime() + value * 24 * 60 * 60 * 1000
    );

    return valueToDate >= minDateValue && valueToDate <= maxDateValue;
  };

  const processFile = (file) => {
    if (file) {
      setFileName(file.name);
      setLoadingImportingRegisters(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });

        if (jsonData.length > 0) {
          const columnsObject = jsonData[0].map((col) => ({
            name: col,
          }));
          setColumns(columnsObject);
          setDataAssociationToDatalakeVisible(true);

          const rowsData = jsonData.slice(1).map((row) => {
            return columnsObject.reduce((acc, col, index) => {
              let cellValue = row[index];

              if (isDate(cellValue)) {
                const parsedDate = XLSX.SSF.parse_date_code(cellValue);
                if (parsedDate) {
                  cellValue = new Date(
                    parsedDate.y,
                    parsedDate.m - 1,
                    parsedDate.d
                  )
                    .toISOString()
                    .split("T")[0];
                }
              } else if (typeof cellValue !== "string") {
                cellValue = String(cellValue);
              }
              acc[col?.name] = cellValue;
              return acc;
            }, {});
          });
          setRowsData(rowsData);
        }
        setLoadingImportingRegisters(false);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const chunkArray = async (
    formattedRows: { fileName: string; columns: string[] },
    size: number,
    fileId: number
  ) => {
    const { fileName, columns } = formattedRows;
    const result: { fileName: string; columns: string[]; last: boolean }[] = [];

    for (let i = 0; i < columns.length; i += size) {
      const isLastChunk = i + size >= columns.length;
      const chunk = {
        fileName: fileName,
        columns: columns.slice(i, i + size),
        last: isLastChunk,
        fileId: fileId,
      };
      result.push(chunk);
    }
    return result;
  };

  const handleSubmit = async (values) => {
    setLoadingImportingRegisters(true);
    setShowCancelButton(true);
    setAllChunksCompleted(false);
    const { selectedColumns } = values;

    const updatedDatabaseRows = databaseColumns.map((dbCol, dbIndex) => {
      const selectedIndex = selectedColumns[dbIndex];
      if (selectedIndex !== undefined) {
        return {
          ...dbCol,
          rows: rowsData.map((row) => row[columns[selectedIndex].name]),
        };
      }
      return dbCol;
    });

    const rowCount = Math.max(
      ...updatedDatabaseRows.map((col) => col.rows.length)
    );
    const formattedRows = { fileName: fileName, columns: [] };

    for (let i = 0; i < rowCount; i++) {
      const row = {};
      updatedDatabaseRows.forEach((col) => {
        row[col.keyName] = col.rows[i];
      });
      formattedRows.columns.push(row);
    }

    try {
      const fileId = await saveDatabaseUploadedFile(
        database.id,
        fileName,
        rowsData.length
      );
      const chunks = await chunkArray(
        formattedRows,
        settings.RowsPerDatabaseUploadPost.Default,
        fileId
      );

      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        await onFinish(chunk, abortController.current.signal);
        setDoneRows((prev) => prev + chunk.columns.length);
        setDonePercentage(((i + 1) / chunks.length) * 100);

        if (chunk.last) {
          setAllChunksCompleted(true);
          setLoadingImportingRegisters(false);
          setDataAssociationToDatalakeVisible(false);
          hide();
        }
      }
    } catch (error) {
      showToast({
        severity: "error",
        message: <LanguageProvider id={"database.upload.file.error"} />,
      });
    }
  };

  useEffect(() => {
    if (donePercentage === 100) setUploadRegistersEnded(true);
  }, [donePercentage]);

  const dialogHeader = () => {
    return (
      <>
        {!dataAssociationToDatalakeVisible ? (
          <span className="upload-data-dialog-title">
            <LanguageProvider id={"database.upload.file.title"} />
          </span>
        ) : (
          <span className="data-association-dialog-title">
            <LanguageProvider id={"database.upload.file.title.map.columns"} />
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    if (allChunksCompleted && fileUploadConcluded) {
      setLoadingImportingRegisters(false);
      setDataAssociationToDatalakeVisible(false);
      setShowCancelButton(false);
      setUploadRegistersEnded(false);
      hide();
    }
  }, [allChunksCompleted, fileUploadConcluded]);

  return (
    <Dialog
      visible={visible}
      onHide={hide}
      header={dialogHeader}
      headerStyle={{
        padding: "30px 40px",
        borderBottom: "1px solid var(--systemBackground)",
      }}
      contentStyle={{ width: "600px", height: "437px", padding: "0" }}
      closeIcon={
        <>
          <Icon
            icon={"x-close"}
            size={20}
            color={currentTheme.textTertiary}
            onClick={() => {
              if (showCancelButton) handleCancel();
              else hide();
            }}
          />
        </>
      }
    >
      <>
        {!dataAssociationToDatalakeVisible ? (
          <div className="upload-data-dialog">
            <div
              className="upload-file-container"
              onClick={() => fileInputRef.current.click()}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
            >
              <div className="upload-file-wrapper">
                <img className="upload-file-img" src={uploadImg} alt="Upload" />
                <input
                  className="upload-file-input"
                  ref={fileInputRef}
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileUpload}
                />
                <span className="upload-input-description">
                  <LanguageProvider
                    id={"database.upload.file.title.drag.file.description"}
                  />
                </span>
              </div>
            </div>
            <span className="upload-file-format">
              <LanguageProvider
                id={"database.upload.file.permitted.extensions"}
              />
            </span>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form className="form-container" placeholder={"Form"}>
                <div className="data-from-to-dialog">
                  <div className="data-from-to-dialog-wrapper">
                    <p>
                      <LanguageProvider
                        id={"database.uploaded.file.subtitle"}
                      />
                    </p>
                    <div className="column-mapping">
                      <div className="column-mapping-left">
                        <h4>
                          <LanguageProvider
                            id={"database.uploaded.file.destiny"}
                          />
                        </h4>
                        {databaseColumns.map((dbCol, index) => (
                          <div className="column-database-wrapper" key={index}>
                            <div className="column-mapping-row">
                              <InputText
                                className="column-mapping-input"
                                type="text"
                                defaultValue={dbCol.name}
                                disabled
                                required
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="column-mapping-right">
                        <h4>
                          <LanguageProvider
                            id={"database.uploaded.file.origin"}
                          />
                        </h4>
                        {databaseColumns.map((dbCol, index) => (
                          <div key={index} className="column-mapping-item">
                            <Field name={`selectedColumns[${index}]`}>
                              {({ field }) => (
                                <Dropdown
                                  className="column-mapping-dropdown"
                                  value={values.selectedColumns[index]}
                                  options={columns.map((col, i) => ({
                                    label: col.name,
                                    value: i,
                                  }))}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `selectedColumns[${index}]`,
                                      e.value
                                    )
                                  }
                                  placeholder={LanguageProvider({
                                    id: "gen.datalakes.select.column",
                                  })}
                                />
                              )}
                            </Field>
                            {errors.selectedColumns &&
                              touched.selectedColumns &&
                              touched.selectedColumns[index] && (
                                <div className="p-error">
                                  {errors.selectedColumns[index]}
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="progress-header">
                    {!uploadRegistersEnded ? (
                      <LanguageProvider
                        id={"database.upload.file.upload.progress"}
                      />
                    ) : (
                      <LanguageProvider
                        id={"database.upload.file.saving.registers"}
                      />
                    )}
                  </div>
                  <div className="bar-wrapper">
                    <div className="bar">
                      <span className="background-fill"></span>
                      <span
                        className="value"
                        style={{
                          background: CHART_COLORS[1],
                          width: `${donePercentage}%`,
                        }}
                      ></span>
                    </div>
                    <span className="percentage-label">
                      {`${doneRows} / ${rowsData.length}`}
                    </span>
                  </div>
                  <div className="p-dialog-footer">
                    <div className="buttons-container">
                      {!showCancelButton && (
                        <Button
                          className="p-button p-button-outlined cancel-btn"
                          type="button"
                          onClick={() => {
                            setDataAssociationToDatalakeVisible(false);
                          }}
                        >
                          <LanguageProvider id={"gen.back"} />
                        </Button>
                      )}
                      {showCancelButton && (
                        <Button
                          className="p-button p-button-danger stop-btn"
                          type="button"
                          onClick={handleCancel}
                        >
                          <LanguageProvider id={"gen.cancel"} />
                          <Icon icon={"stop"} size={20} color={"white"}></Icon>
                        </Button>
                      )}
                      <Button
                        className="p-button p-button-primary submit-btn"
                        type="submit"
                        loading={loadingImportingRegisters}
                      >
                        <LanguageProvider id={"gen.confirm"} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    </Dialog>
  );
};
